

// Base64 문자열을 URL-safe 형식으로 변환
export const toUrlSafeBase64 = (base64)=> {
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// URL-safe 형식을 원래의 Base64 형식으로 복원
export const fromUrlSafeBase64 = (base64)=> {
    base64 = base64.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4) {
        base64 += '=';
    }
    return base64;
}