
import {BsFillTelephoneFill} from 'react-icons/bs';
import {MdEmail, MdFax} from 'react-icons/md';
import { Footer } from '../../../common/footer/A/Footer';
import '../../../../resources/css/common/common.css';

export const Contact = ({title})=>{
    return <div id='contact' className="container" data-title={title}>
            <div className="container_inner">
                <div className='title center'>
                    <h3>{title}</h3>
                </div>
                <div className='contents'>
                    <ul className='contents_tell'>
                        <li><BsFillTelephoneFill /> TEL : 031-232-6855</li>    
                        <li><MdEmail /> E-MAIL : educoding@naver.com</li>  
                    </ul>
                </div>
            </div>
            <Footer />
        </div>


}