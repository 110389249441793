import Cookies from "js-cookie";


const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;

export const setCookieDuration = (cookieName,cookieValue,days)=>{

    if(days === 0) return ()=> console.error("setCookie를 사용하세요!");

    const expirationMilliseconds = daysToMilliseconds(days);
    // 1. 쿠키 설정
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + expirationMilliseconds);
    Cookies.set(cookieName, cookieValue, { expires: expirationDate });

    // 2. 지정한 기간 후에 쿠키 삭제
    const deleteCookieAfterExpiration = setTimeout(() => {
        Cookies.remove(cookieName);
    }, days * 24 * 60 * 60 * 1000);

    // 함수 정리(clean-up)
    return () => clearTimeout(deleteCookieAfterExpiration);
}

export const getCookies = (cookieName)=>{
    return Cookies.get(cookieName);
}