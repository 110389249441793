import styled from "styled-components"
import { getProductList} from "../../../data/product/product"
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import pointData from "../../../../resources/lottie/product/nullPoint.json";
import lottie from "lottie-web";
import axios from "axios";
import { API, FOLDER, S3 } from "../../../data/config";
import { toUrlSafeBase64 } from "../../../component/base64";
import CryptoJS from 'crypto-js';
import { getCookie } from "../../../component/cookies";
const ProductContainer = styled.div`
    .product_list_inner {
        max-width: var(--max-width);
        margin: 0 auto;
        padding: 50px 25px;
        padding-bottom: 150px;
    }
    .product_list_inner ul{
        display: flex;
        gap: 100px 30px;
        flex-wrap: wrap;
    }
    .product_list_inner li {
        width: calc((100% - 120px)/5);
        
        position:relative;
    }
    // .product_list_inner li::after{
    //     content: "";
    //     position:absolute;
    //     bottom: -50px;
    //     width: 100%;
    //     display:block;
    //     height:0;
    //     border-bottom: 3px dotted #eaeaea;
    // }
    .product_list_inner li .img_box{
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .product_list_inner li dl {
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        padding-top: 20px;
    }
    .product_list_inner li dl dt{
        color: var(--main-color2);
        font-size: 18px;
        font-weight: 900;
        height: 63px;
    }
    .product_list_inner li dl dt p,
    .product_list_inner .start{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .product_list_inner li dl dd.content{
        font-size: 14px;
        color: var(--font-color-sub);
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .product_list_inner .start{
        font-size: 16px;
        color: var(--sub-color);
        display: flex;
        align-items: center;
    }
    .product_list_inner .start .date-all{
        font-size: 18px;
        color: var(--font-color-title);
        margin-left: 15px;
    }
    .product_list_inner .amount{
        margin-left: auto;
        font-size: 18px;
        text-align:right;
        position:relative;
    }
    .product_list_inner .amount .sale{
        text-align:right;
        display: block;
        font-size: 11px;
        color: var(--font-color-sub);
        text-decoration-line: line-through;
    }
    
    .product_list_inner .view_btn{
        display:flex;
    }
    .product_list_inner .view_btn a{
        width: 100%;
        text-align:center;
        display:block;
        padding: 10px 15px;
        background-color: var(--main-color2);
        color: #fff;
        border-radius: 20px;
    }
    @media only screen and (max-width: 1050px){
        
        .product_list_inner li{
            width: calc((100% - 60px) /3);
            text-align:center;
        }
        .product_list_inner li dl dt{
            flex-direction: column-reverse;
            gap: 20px;
            align-items: center;
        }
        .product_list_inner .start{
            justify-content: center;
        }
        .product_list_inner li dl dd.content{
            margin: 20px 0;
            -webkit-line-clamp: 4;
        }
        .product_list_inner .view_btn a{
            width: 100%;
        }
    }
    .lottie_container{
        width: 150px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 630px){
        .product_list_inner li{
            width: calc((100% - 30px) /2)
        }
        .product_list_inner .start{
            font-size: 12px;
            margin: 20px 0;
        }
        .product_list_inner .start .date-all{
            font-size: 15px;
            margin-left: 5px;
        }
    }

    @media only screen and (max-width: 390px){
        .product_list_inner li{
            width: 100%;
        }
    }
`;  
const NullPoint = ()=>{
    const contentRef = useRef(null);
    useEffect(()=>{
        lottie.loadAnimation({
            container: contentRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: pointData
        });
    }, [contentRef])
    return <>
        <ProductContainer>
            <div className="product_list_inner" style={{height: "100vh", display:"flex",justifyContent:"center",alignItems:"center",flexWrap:"wrap"}}>
                <div className="product_list_inner_cover">
                    <div className="lottie_container" ref={contentRef}></div>
                    <p style={{textAlign: "center", fontSize: "22px"}}>12월초 최종 공지 됩니다.</p>
                </div>
            </div>
        </ProductContainer>
    </>
}

export const ProductList = ({menu_idx}) =>{
    
    const [productList, setProductList] = useState([]);
    const secretKey = "product_idx";
    useEffect(()=>{
        const getProduct = async ()=> {
            const res = await axios.post(API + "/product/getProductList", {menu_idx, viewPage : 100, pageNum : 0});
            setProductList(res.data.contentList);
        }
        getProduct();
    }, [menu_idx])

    //const productList = getProductList(menu_idx);
    return <>
        <ProductContainer className='product_list'>
            <div className="product_list_inner">
                <ul>
                    {productList.map((item, idx) =>{
                        const startDate = (item.startDate) ? moment(item.startDate?.date) : null;
                        
                        const endDate = (item.endDate) ? moment(item.dayArr[item.dayArr.length - 1]?.date) : null;


                        // const diff = endDate?.diff(startDate, 'days');
                        
                        // const months = ((Math.floor(diff / 30)) != 0) ? Math.floor(diff / 30) + "개월 " : "";
                        // const weeks = (Math.floor((diff % 30) / 7) != 0) ? Math.floor((diff % 30) / 7) + "주 " : "";
                        // const days = ((diff % 7) != 0) ? (diff % 7) + "일 ": "";
                        
                        // const all = (months != "") ? months : (weeks != "") ? weeks : days;
                        


                        return (
                            <li key={idx}>
                                <div className="img_box">
                                    <img src={`${S3}/${getCookie("folder")}/product/${item.imgList.find(img => img.img_main === 1)?.img_url}`} alt={item.title} />
                                </div>
                                <dl>
                                    <dt><p>{item.title}</p> 
                                        {item.event > 0 ? (
                                            <div className="amount">
                                                {/* <span className="sale">{item.amount.toLocaleString()}원</span>
                                                <span className="events">{((item.amount - item.event) == 0) 
                                                ? "전액무료" : (item.amount - item.event).toLocaleString()+"원"}</span> */}
                                                {item.amount == 0 && item.comment != null ? (
                                                    <span className="events">{item.comment}</span>
                                                ) : (
                                                    <>
                                                    <span className="sale">{item.amount.toLocaleString()}원</span>
                                                    <span className="events">{(item.event == 100) 
                                                    ? "무료" : (item.amount - (item.amount * item.event / 100)).toLocaleString()+"원"}</span>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="amount">

                                                
                                                <span className="events">{(item.comment == "") ? "미정" : item.comment}</span>
                                                {/* <span>{item.amount.toLocaleString()}원</span> */}
                                            </div>
                                        )}
                                    </dt>
                                    <dd className="start">
                                        {item.start_date && item.end_date ? (
                                            <>
                                            <span className="dates">{item.start_date} ~ {item.end_date}</span>
                                            {/* <span className="date-all"> ( 약 {all} <span className="date-txt">과정 )</span></span> */}
                                            </>
                                        ) : (
                                            <span className="dates">{(item.comment == "") ? "미정" : item.comment}</span>
                                        )}
                                    </dd>
                                    <dd className="view_btn">
                                        <Link to={`/product/view?p=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.product_idx.toString(), secretKey).toString())}`}>신청하기</Link>
                                    </dd>
                                </dl>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </ProductContainer>
    </>
}