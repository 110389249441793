import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import loadingData from "../lottie/data/data.json";
import styled from "styled-components";

const LodingStyle = styled.div`
background-color: rgba(0, 0, 0, 0.2);
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
position: fixed;
left: 0;
top: 0;
width: 100%;
z-index:999;
.loading{
    width:380px;
}
`


export default function Loading(){

    const container = useRef(null);
  
      useEffect(() => {
        Lottie.loadAnimation({
          container: container.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: loadingData
      });
  
      }, []);
      return <LodingStyle className="loading_container">
        <div className="loading" ref={container}></div>
      </LodingStyle>
  }