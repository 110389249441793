import { useEffect, useRef, useState } from "react"



export const Controller = ({children})=> {
    
    return <>
        <div className="controller">
            <ul>
                {children}
            </ul>
        </div>
    
    </>
}