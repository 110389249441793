


import { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { loadMap } from '../../data/map/map';
import styled from 'styled-components';
const MapStyle = styled.div`
#loadMap{
    background-color: #f5f5f5;
}
.load_map_cover{
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    padding: 0 15px;
}
.load_map_cover .load_txt{
    position: absolute;
    right: 15px;
    bottom: -10px;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: 5px 3px 15px 0px rgba(0, 0, 0, .2);
}
@media only screen and (max-width: 800px){
    .load_map_cover .load_txt{
        position: static;
        width: 100%;
    }
}
`
export const Map =()=>{
    const [loadMaps, setLoadMap] = useState(false);

    const mapStyles = {        
        height: "500px",
        width: "100%"
    };
    return <MapStyle> 
        <div className='load_map_cover'>
                    
        <LoadScript
            googleMapsApiKey='AIzaSyAn-JB6HRN2mVdcE7smQDRVMugtaHSq024'
            onLoad={() => setLoadMap(true)}
        >
            {loadMaps && (
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={17}
                    center={loadMap}
                    gestureHandling="greedy"
                >
                    <Marker position={loadMap}/>
                </GoogleMap>
            )}
        </LoadScript>
            <div className='load_txt'>
                <h4>경기도 화성시 경기대로 1014 , 303호 일부(우측) (병점동, 병점프라자)</h4>
                <span className=''>병점역 1번출구 도보 10분</span>
            </div>
    </div>
    </MapStyle>
}