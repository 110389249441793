import styled from "styled-components";
import { popup } from "../data/popup/popup"
import { useRef, useState } from "react";
import { getCookies, setCookieDuration } from "../prop/cookies";



const PopupStyle = styled.div`
    position: fixed;
    left: 50px;
    top: 100px;
    max-width: 300px;
    z-index: 9;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .2);
    .img_box{
        width: 100%;
    }
    .img_box img{
        width: 100%;
    }
    .close_box{
        background-color: #fff;
        display:flex;
        padding: 10px 5px;
    }
    .close_box > span{
        margin-left: auto;
        cursor:pointer;
        font-size: 18px;
    }
    .close_box label{
        cursor:pointer;
        font-size: 18px;
    }
    .close_box label span{
        margin-left: 5px;
    }
    @media only screen and (max-width: 500px){
        max-width: 280px;
        left: 10px;
    }
    @media only screen and (max-width: 300px){
        max-width: 260px;
    }
`;

export const Popup = ()=> {
    const [checked, setChecked] = useState(false);
    const popRef = useRef(null);
    if(popup.time == 0) return null;
    if(getCookies("popup")) return null;
    const items = popup;
    const closeFunction = ()=>{
        if(checked){
            setCookieDuration("popup", true, popup.time);
        }
        popRef.current.style.display= "none";

    }
    const check = ()=> (checked) ? setChecked(false) : setChecked(true);
    return <>
        <PopupStyle className="popup" ref={popRef}>
            <div className="img_box">
                <img src={items.img} alt={items.title} />
            </div>
            <div className="close_box">
                <label>
                    <input type="checkbox" onChange={check} value={checked} checked={checked} />
                    <span>{items.txt}</span>
                </label>
                <span onClick={closeFunction}>닫기</span>
            </div>
        </PopupStyle>
    </>
}