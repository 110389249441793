
import { Link } from 'react-router-dom';
import {Logo} from '../../resources/images/logo.png';

export const NotFound = ()=> {
    return <>
        <div className="notFound">
            <div className="notFound_inner">
                <h3>
                <a href="/">
                    
                </a>
                </h3>
                <div className='txt-notFound'>
                    <p>현재 내용을 준비하고있습니다.</p>
                    <p>문의 사항은 다음 정보를 참고해주세요</p>
                    <p>이메일 : educoding@naver.com</p>
                    <p>전화 : 031-232-6855</p>
                </div>
                <div className="button">
                <Link to="/"><span>메인페이지로</span></Link></div>    
            </div>    
        </div>
        
    </>
}