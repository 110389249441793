import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import { fromUrlSafeBase64 } from '../../component/base64';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API } from '../../data/config';
import styled from 'styled-components';

const NoticeStyle = styled.div`
    padding: 100px 0;
    
    .notice_container{
        max-width: var(--max-width);
        margin: 0 auto;
        padding: 100px 25px;
    }
    table td, th{
        border: 1px solid #333;
        padding: 15px 10px;
    }
    table{
        width:100%;
    }
    img{
        width:auto;
        height: auto;
    }
    .html{
        padding: 50px 25px;
    }
`

export const Notice = ()=> {
    const secretKey = "notice_idx";
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const idx = fromUrlSafeBase64(query.get('n'));
    const bytes = CryptoJS.AES.decrypt(idx, secretKey);
    const notice_idx = Number(bytes.toString(CryptoJS.enc.Utf8));

    const [item, setItem] = useState(null);

    const [regdate, setRegDate] = useState("");

    useEffect(()=>{
        const getNotice = async ()=>{

            const res = await axios.post(API + "/notice/getNotice", {notice_idx});
            setItem(res.data);
            const date = new Date(res.data.notice_regdate);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            const str = `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
            setRegDate(str);
        }
        getNotice();
    }, []);

    return <NoticeStyle>
            <div className='notice_container'>
                <table>
                    <colgroup>
                        <col width={"100px"}></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>제목</th>
                            <td>{item?.notice_title}</td>
                        </tr>
                        <tr>
                            <th>작성날짜</th>
                            <td>{regdate}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>내용</th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className='html' dangerouslySetInnerHTML={{__html: item?.notice_content}}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </NoticeStyle>
}