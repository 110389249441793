import { useEffect, useState } from "react";
import { MdManageSearch } from "react-icons/md";
import styled from "styled-components"
import { AlpakaPaging } from "../../component/Paging/Paging";


const NoticeListStyle = styled.div`
.container{
    max-width: var(--max-width);
    margin: 0 auto;
}
.title{
    display:flex;
    align-items: center;
}
.title span.arrow{
    margin-left: auto;
    display: block;
    width: 30px;
    height: 30px;
    position:relative;
    cursor: pointer;
}
.title span.arrow::before, .title span.arrow::after{
    content: "";
    display:inline-block;
    top: 50%;
    position: absolute;
    width: 10px;
    height: 1px;
    background-color :#333;
}
.title span.arrow::before{
    left:7px;
    transform : rotate(45deg);
}
.title span.arrow::after{
    right: 6px;
    transform : rotate(-45deg);
}

.state-wrap {
    overflow: hidden;
    padding: 20px 25px;
    background-color: rgba(255,255,255,.3);
    margin: 5px 0;
}
.state-group strong { display: inline-block;border-left:5px solid #3d3d3d; padding-left: 5px;}
.state-group {float: left;}
.state {font-size: .95em;text-align: center;}
.state-group .state + .state {margin-left: 10px;}
.state span {padding: 3px 2px 1px 2px; display: inline-block;width: 18px;height: 18px; font-size: 12px;color: #fff;}
.state .undecided {border-radius:20px;background-color: #0abab5;}
.state .edit {border-radius:20px;background-color: #82cf54;}
.state .delete {border-radius:20px;background-color: #fc2d59;}
.state .complete {border-radius:20px;background-color: #4169e1;}
.total {float: right;font-size: 15px;color: #fff;}
.total span {font-size: 13px;}
.per {background:#525252;display: inline-block;padding: 2px 10px 4px; margin-right: 20px;}
.status { background-color: #5f6061; display: inline-block;padding: 2px 10px 4px;}
.quest {background: #f9f1d0;}
.comp {background: #f2f2f2;}
.result select {
    border: 0;
    background-color:#525252;
    font-size: 14px;
    color:#fff;
    text-align:center;
    cursor: pointer;
}
.search_tools{
    padding: 20px 35px;
    display:block;
    text-align:right
}
.search_tools span{
    display: inline-block;
}
h2{
    display: flex;
    justify-content: start;
    align-items: center;
}
h2 button{
    padding: 0;
    margin-left: auto;
}
h2 button a{
    display:block;
    color:#fff;
    padding: 10px 20px;
}
`
const SarchBtn = ()=> {
    return <>
        <MdManageSearch style={{marginLeft: "auto", display: "block", fontSize: "38px", color: "var(--main-color)",cursor:"pointer"}} />
    </>
}

export const NoticeList = ({menu_idx})=> {
    const [limit, setLimit] = useState(10);
    const [req, setReq] = useState({
        menu_idx,
        notice_title : "",
    });
    

    const column = [
        {
            title: "제목",
            column : "notice_title",
            link : "/notice/view?n"
        }
    ]


    return <NoticeListStyle>
        <section>
        <div className="container">
                {/* <div className="content title_box">
                    <h2><span>{menu_name}</span><button type="button"><Link to={`/notice/board?p=${queryParams.get('p')}`}>게시글 작성</Link></button></h2>
                </div> */}
                <div className="content">
                    
                    <div className="content_notice">
                        <div className="state-wrap">
                            {/* <div className="state-group">
                                <strong>상태</strong> : &nbsp;
                                <span className="state"><span className="undecided"></span></span> 정상
                                <span className="state"><span className="edit"></span></span> 수정됨
                                <span className="state"><span className="delete"></span></span> 비공개
                            </div> */}
                            <p className="total">
                                <span className="per">게시물 : 
                                    <span className="result">
                                        <select value={limit} onChange={(e)=>setLimit(parseInt(e.target.value))}>
                                            <option value={10}>10</option>
                                            <option value={30}>30</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        개씩 보기
                                    </span>
                                </span>
                                <span className="comp"></span>
                            </p>
                        </div>
                        <AlpakaPaging 
                            request={req}
                            columnTarget={"notice_idx"}
                            column={column}
                            secretKey={"notice_idx"}
                            api={"/notice/getList"}
                            viewPage={limit}
                            onSearch={(search) => setReq((prev) => prev={...prev, notice_title: search})}
                        >
                        </AlpakaPaging>
                    </div>
                </div>
            </div>
        </section>
    </NoticeListStyle>
}