

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import koLocale from '@fullcalendar/core/locales/ko';
import styled from 'styled-components';

const CalendarStyle = styled.div`
  .fc .fc-toolbar {
    justify-content: center;
    gap:10px;
    align-items:center;
  }
`;

const time = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':');
  let hoursInt = parseInt(hours, 10);

  // 오후/오전 구분
  const ampm = hoursInt >= 12 ? '오후' : '오전';

  // 12시간 형식으로 변환
  hoursInt %= 12;
  hoursInt = hoursInt || 12; // 0시일 경우 12시로 표시

  // 분이 있는 경우 분 추가
  let formattedTime = `${ampm} ${hoursInt}시`;
  if (minutes && minutes !== '00') {
    formattedTime += `${parseInt(minutes, 10)}분`;
  }

  return formattedTime;
}
export const Calendar = ({ dayArr }) => {
    if(dayArr.length == 0) return null;
    // dayArr의 첫 번째와 마지막 날짜 추출
    const firstDate = new Date(dayArr[0].date);
    const lastDate = new Date(dayArr[dayArr.length - 1].date);
  
    // 첫 번째와 마지막 날짜 사이의 모든 날짜 생성
    const dateArray = [];
    


    for(let obj of dayArr){
      const title = `${time(obj.startTime)} ~ ${time(obj.endTime)}`;
      dateArray.push({title: title, date: obj.date});
    }


    return (
      <CalendarStyle>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        validRange={{ start: firstDate, end: lastDate }}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next',
        }}
        events={dateArray}
        locale={koLocale}
        
      />
      </CalendarStyle>
    );
  };