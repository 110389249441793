import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Tags from './application/Tag';
import { HeadHemelt } from './application/HelmetInfo';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <HeadHemelt ></HeadHemelt>
    <Tags />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
