import { createGlobalStyle } from "styled-components";


export const CommonStyleMain_A = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');


body {
	font-family: "Roboto", "Noto Sans KR", sans-serifsnas;
}

table {
    border-collapse: collapse;
}
:root {
    --main-color: #a63145;
    --main-color2: #f04e4c;
	--sub-color: #595757;
	--font-color-title: #333333;
	--font-color-sub:#6E6E6E;
	--scroll-color-back:#eeeeee73;
	--scroll-color:#7c87988e;
    --max-width: 1290px;
	--font-size-title: 1.125em;
	
}
@media screen {
	
}

input[type=submit],input[type=button],button[type=button]{
	padding: 10px 20px;
	font-size: 0.875em; /* 14 */
	color: #fff;
	font-family: "Open Sans", sans-serif;
	background-color: var(--main-color);
	border: none;
	outline: 0;
	box-sizing: border-box;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	font-weight:500;
}
button[type=button].back_red{
    background-color: red;
    color: #fff;
}

.alert_container{
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    justify-content: center;
    align-items: center;
    z-index: 999999999;
}
.alert_cover{
    max-width: 500px;
    padding: 25px 50px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
}
.alert_cover p{
    font-size: 22px;
    padding-bottom: 15px;
}


.dim_alert{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.0);
    z-index: 9;
    opacity: 0.5;
    display: none;
}
.modal_alert{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    cursor: pointer;
}
.modal_alert .modal_alert_table{
    display: table;
    width: 100%;
    height: 100vh;
}
.modal_alert .modal_alert_table .modal_alert_table_cell{
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px;
}
.modal_alert .modal_alert_table .modal_alert_table_cell .modal_alert_table_inner{
    max-width: 300px;
    margin: 0 auto;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 5px;
    padding: 5px;
    overflow: auto;
}
.modal_alert .modal_alert_table .modal_alert_table_cell .title h3{
    float: left;
}
.modal_alert .modal_alert_table .modal_alert_table_cell .pop_text1{
    text-align: center;
    color: #fff;
    -webkit-user-select:none; 
    -moz-user-select:none; 
    -ms-user-select:none; 
    user-select:none;
}

span.prev{
    position: relative;
    display: inline-block;
    width: 12px;
}
span.prev::before{
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    width: 12px;
    height: 2px;
    border-radius: 50px;
    transform: rotate(-45deg);
    background-color: var(--main-color);
}
span.prev::after{
    content: "";
    display: block;
    position: absolute;
    top: 17px;
    width: 12px;
    height: 2px;
    border-radius: 50px;
    transform: rotate(45deg);
    background-color: var(--main-color);
}
span.all{
    line-height: 1.8;
    color: var(--main-color);
}
span.next{
    position: relative;
    display: inline-block;
    width: 12px;
}
span.next::before{
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    width: 12px;
    height: 2px;
    border-radius: 50px;
    transform: rotate(45deg);
    background-color: var(--main-color);
}
span.next::after{
    content: "";
    display: block;
    position: absolute;
    top: 17px;
    width: 12px;
    height: 2px;
    border-radius: 50px;
    transform: rotate(-45deg);
    background-color: var(--main-color);
}

.blue{
    color: blue;
}
.red{
    color: red;
}

#mask{
    display: flex;
    justify-content: center;
    align-items: center;
}

i{
    margin: 0 10px;
}

a.ytp-title-channel-logo, .ytp-title-text, .ytp-title{
    display: none;
}
.styleNone{
    display: none;
}


.route{
	padding-top: 50px;
}
@media only screen and (max-width: 500px) {
	.route{
		padding: 50px 0;
		font-size: 14px;
		text-align: center;
	}
}

.notFound{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    
}
.notFound_inner{
    text-align: center;
    
}
.notFound .txt-notFound{
    line-height: 48px;
}
.notFound .button {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    overflow:hidden; 
    cursor:pointer; 
    
    font-size :18px;  
    border-radius :45px;  
    box-shadow :6px 6px 20px rgba(122,122,122,.5);
}


.notFound .button:hover {background:#ff6700;}

.notFound .button span {
    position:relative; 
    z-index :1;
    color:#fff; 
}

.notFound .button::before {
    content:"";
    position:absolute; 
    left:-100%; 
    top:-100%;   
    height :200%;   
    width :200%;
    background :#ff6700 ;    
    transition:.5s ease-in-out ;
    transform :rotate(45deg);    
    box-shadow :-1px -1px ,2px 2pX ,3PX -3PX ,4PX 4PX ;
    animation:giffy .7s infinite ;
}

.notFound .button:hover::before{left:-30% ; top:-30% ;}




`