import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const lectureList = [
    {
        name : "김상모",
        jab: "원장",
        subject: "직강",
        Intro : ()=> {
            return <ul>
                <li>현) AI, 스마트팩토리 전문기업 컨시스 대표</li>
                <li>현) 경기 IT 프로그래밍 직업 교육 학원 원장</li>
                <li>전) 부천대학교 지능로봇과 겸임교수</li>
                <li>전) 삼성전자/미래산업 자동화 제어 SW 선임/책임 연구원</li>
                <li>멋쟁이 사자처럼, 패스트캠퍼스, 구름, 미림미디어랩등 최고의 교육기관 대표강사 경력</li>
                <li>서울 강남에서의 최고의 IT 강의를 직접 경험하세요!</li>
                <li>- 인공지능/데이터 분석 부트캠프 </li>
                <li>- K-Digital Training 인공지능 취업연계 과정</li>
                <li>- ICT 이노베이션 파이썬/머신러닝/딥러닝 과정</li>
                <li>- 하이 서울 딥러닝 전문가 양성 과정</li>
                <li>- 청년취업아카데미 자연어처리 전문가 양성과정</li>
            </ul>
        },
        img : "/asd",
        imgBack: "../../../resources/images/lecture1.jpg"
    }
]

export const Lecture = ({title})=>{
    return <div id='lecture' className="container" style={{backgroundColor: "#B8183A"}} data-title={title}>
        <div className="container_inner">
            <div className='title'>
                <h3>{title}</h3>
                <span>ITP 강사를 소개합니다.</span>
            </div>
            <div className="contents">
                <Swiper navigation={true} modules={[Navigation]}>
                    {lectureList.map((elem, idx) => (
                        <SwiperSlide key={idx}>
                            <div className='slide_cover'>
                                <Link to="" className="slide_title">
                                    <span className='image_sizing'><img src={require('../../../../resources/images/kim.jpg')} alt={elem.name} /></span>
                                    <h5>{elem.name} <span>{elem.jab}</span></h5>
                                </Link>
                                <div className='slide_contents'>
                                    <dl>
                                        <dt>{elem.subject}</dt>
                                        <dd>{<elem.Intro></elem.Intro>}</dd>
                                    </dl>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    </div>
}