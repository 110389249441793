import { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "./data.json";

export default function ProductNotFind({msg}){

  const container = useRef(null);

    useEffect(() => {
      lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
    });

    }, []);
    return <div className="notFound_container">
      <div className="notFound" ref={container}></div>
      <div className="notFound_txt">
        {msg}
      </div>
    </div>
}