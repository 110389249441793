import { Route, Routes, useLocation } from "react-router-dom";
import './resources/css/reset/reset.css';
import React, { useEffect, useState } from "react";
import { Header } from "./templates/common/header/A/Header";
import { Home } from "./templates/home/A/Main";
import { NotFound } from "./templates/component/NotFound";
import { SubPage } from "./templates/subPage/product/A/SubPage";
import { subPagehtml } from "./templates/data/menu/menu";
import { Footer } from "./templates/common/footer/A/Footer";
import { ScrollTop } from "./templates/component/ScrollTop";
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import { API } from "./templates/data/config";
import axios from 'axios';

import { getCookie, setCookie } from "./templates/component/cookies";
import { Product } from "./templates/subPage/product/A/Product";
import { Notice } from "./templates/subPage/notice/Notice";
import HomeSA from "./templates/home/S_A/Main/Main";
import { CommonStyleMain_SA } from "./templates/home/S_A/Common/styled/CommonStyleMain";
import { createGlobalStyle } from "styled-components";
import { CommonStyleMain_A } from "./templates/home/A/styled/CommonStyleMain";
import HeaderS_A from "./templates/common/header/S_A/Header";
import Loading from "./templates/home/S_A/Common/lottie/Loding";
import ProductSA from "./templates/subPage/product/S_A/Product";
import { SubPageSA } from "./templates/subPage/product/S_A/SubPage";



function App() {
  

  const [server, setServer] = useState({
    site_id : getCookie("site_id"),
    site_style : getCookie("site_style"),
    site_name : getCookie("site_name")
  });

  const [mapLoad, setMapLoad] = useState(false);
  
  const [subHtml, setSubHtml] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    

    const fetchSubPage = async () => {
      const response = await axios.post(API + "/menu/html", { site_id: server.site_id });
      setSubHtml(response.data);
      setMapLoad(true);
    }
    fetchSubPage();
  }, []);

  if (server === null || server === undefined) {
    return <div></div>;
  }
  return (
    <>
      {mapLoad && server?.site_style === "A" && (
        <StyleA path={path} subHtml={subHtml} />
      )}

      {mapLoad && server?.site_style === "S_A" && (
        <StyleS_A path={path} subHtml={subHtml} />
      )}
    </>
  );
}




// style R
/*
const Style_R = ({path, subHtml})=>{
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 데이터 로딩을 시뮬레이션하기 위한 setTimeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 3초 후 로딩 상태를 false로 설정
  }, []);

  if (isLoading) {
    return <Loading />; // 로딩 중일 때 로딩 아이콘 표시
  }


  return <>
    <CommonStyleMain_R />
    <Routes>
      <Route path="/" element={<Main_R />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
}*/


// style s_a
const StyleS_A = ({path, subHtml})=>{
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 데이터 로딩을 시뮬레이션하기 위한 setTimeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 3초 후 로딩 상태를 false로 설정
  }, []);

  if (isLoading) {
    return <Loading />; // 로딩 중일 때 로딩 아이콘 표시
  }


  return <>
    <CommonStyleMain_SA />
    <ScrollTop />
    <HeaderS_A/>
    <Routes>
      {subHtml.map((elem, idx)=> 
        elem.menu_type_idx != 4 && (
          <Route key={idx} path={elem.menu_url} element={<SubPageSA {...elem} />} />
        )
      )}
      <Route path="/" element={<HomeSA />} />
      <Route path="/product/view" element={<ProductSA />} />
      <Route path="/notice/view" element={<Notice />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
}







const StyleA = ({path, subHtml})=>{
  


  useEffect(() => {
    if(isBrowser){
      const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
      
      window.addEventListener('resize', setVh);
      setVh(); // 초기 렌더링 시에도 vh를 설정합니다.
      return () => {
        window.removeEventListener('resize', setVh); // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
      };
    }
  }, []);
  return <>
        <CommonStyleMain_A />
        {path != "/" && (
          
          <>
            {/*<Alert view={isAlertVisible} msg={alertMsg} handle={alertHandle} />*/}
            <ScrollTop />
          </>
        )}
        <Header />
          <section>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/subject/product/view" element={<Product />} /> */}
              {subHtml.map((elem, idx)=> 
                elem.menu_type_idx != 4 && (
                  <Route key={idx} path={elem.menu_url} element={<SubPage {...elem} />} />
                )
              )}
              <Route path="/product/view" element={<Product />} />
              <Route path="/notice/view" element={<Notice />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </section>
        {path != "/" && (
          <Footer />
        )}
      </>
}




export default App;


