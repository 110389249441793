import React, { useEffect, useState } from 'react'; // React import 수정
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { API } from '../templates/data/config';
import { getCookie, removeCookie, setCookie } from '../templates/component/cookies';
import { toUrlSafeBase64 } from '../templates/component/base64';

function Tags() {
  // useState를 사용하여 상태 관리
  const [server, setServer] = useState({
    site_id: getCookie("site_id"),
    site_style: getCookie("site_style"),
    site_name: getCookie("site_name")
  });

  const sec_key = process.env.REACT_APP_SEC_KEY;
  const host = window.location.hostname;
  const http = (host.includes('localhost') || host.includes('127.0.0.1'))
    ? "https://www.educoding.co.kr/"
    : host;
  useEffect(() => {
    if (server.site_id === null || server.site_id === undefined) {
      const fetchServerName = async () => {
        const response = await axios.post(API + "/home/homeInfo", { site_id: http });
        const data = {
          site_id: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_id, sec_key).toString()),
          site_style: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_style, sec_key).toString()),
          site_name: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_name, sec_key).toString()),
          folder : toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_eg_name, sec_key).toString())
        }

        setCookie("SESSION_SEQ", data);
        setServer({
          site_id: data.site_id,
          site_style: data.site_style,
          site_name: data.site_name
        });

        if (response.status === 200) {
          window.location.reload();
        }
      };

      fetchServerName();
   /* } else{
      if(getCookie("folder") == undefined){
        removeCookie("SESSION_SEQ");
        window.location.reload();
      }*/

    }


  }, [server.site_id]); // 의존성 배열에 server.site_id 추가
  
  
  
  return (
    <div>
        
        {server.site_style === "S_A" && (
          <Helmet>
            <title>{server.site_name}</title>
          </Helmet>
        )}

        {server.site_id === "https://www.educoding.co.kr/" && (
          
          <Helmet>
            <title>{server.site_name}</title>
            
          </Helmet>
        )}
    </div>
  );
}

export default Tags;