export const transformContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const oembeds = doc.querySelectorAll('oembed[url]');

    oembeds.forEach(oembed => {
        const url = oembed.getAttribute('url');
        if (url) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', `https://www.youtube.com/embed/${extractVideoID(url)}`);
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
            iframe.setAttribute('allowfullscreen', 'true');
            iframe.style.width = '100%';  // Adjust size as needed
            iframe.style.height = '500px';  // Adjust size as needed

            oembed.parentNode.replaceChild(iframe, oembed);
        }
    });

    return doc.body.innerHTML;
};

export const extractVideoID = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
};