import { useEffect } from "react"


export const ScrollTop = ()=> {
    
    useEffect(()=>{
        window.scrollTo({
            top:0
        })
    })
    
    return <></>
}