import { useContext, useEffect, useState } from "react";
import styled from "styled-components"
import { getProductView } from "../../../data/product/product";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Calendar } from "../../../component/Calendar";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "../../../component/base64";
import { API, FOLDER, S3 } from "../../../data/config";
import axios from "axios";
import { getCookie } from "../../../component/cookies";
import { NotFound } from "../../../component/NotFound";
import { transformContent } from "../../../component/ckeditor/videoView";
import Loading from "../../../home/S_A/Common/lottie/Loding";
const ProductStyle = styled.div`
background-color : #eaeaea;
.table table td, .table table th{
    border: 1px solid #bfbfbf;
    min-width: 2em;
    padding: 0.4em;
}
.table{
    margin: 0.9em auto;
}
.table table{
    width: 100%;
}
.table td, .table th{
    overflow-wrap: break-word;
    position: relative;
}
.amount p{
    color:var(--main-color);
}
.events{
    color: var(--main-color);
}
    .product_inner{
        max-width: var(--max-width);
        margin: 0 auto;
        padding: 130px 25px;
        background-color : #eaeaea;
    }
    .product_inner_cover{
        
        padding: 50px 0;
        background-color: #fff;
    }
    .product_box{
        display:flex;
        justify-content: start;
        gap: 50px;
    }
    .product_inner_cover .left{
        width: calc((100% - 50px)/2);
        padding: 0 50px;
    }
    .product_inner_cover .left .img_box{
        width: 210px;
        border: 1px solid #ccc;
    }
    img{
        width: 100%;
    }
    .product_inner_cover .right{
        width:100%;
        padding: 0 50px;
        text-align:right;
        position:relative;
    }
    .product_inner_cover .right .saleStop{
        width:100%;
        position:absolute;
        left:0;
        top:0;
        height:100%;
        background-color:rgba(0,0,0,0.4);
        color:#fff;
        font-size: 25px;
        display:flex;
        justify-content:center;
        align-items: center;
        font-weight:900;
    }
    .product_inner_cover .right h4{
        font-size: 28px;
        color: #333;

    }
    .product_inner_cover .right table{
        width: 100%;
        margin: 50px 0;
        line-height: 40px;
    }
    .left ul{
        display:flex;
        width: 210px;
        gap: 5px;
        padding-top: 20px;
        justify-content: center;
    }
    .left ul li{
        width: calc((100% - 20px)/5);
        overflow:hidden;
        border: 1px solid #ccc;
        cursor: pointer;
    }
    .left ul li.active{
        border: 2px solid #333;
    }

    .inquiry .title{
        padding-bottom: 20px;
    }

    .inquiry {
        width: 100%;
        background: url('/images/sub/contact_background.jpg') no-repeat center center;
        background-size: cover;
        padding: 6.250em 25px 5.188em;
        font-size: 1em;
    }
    .inquiry .inquiry_inner{
        max-width: var(--max-width);
        margin:0 auto;
    }
    .inquiry .inquiry_inner .title h3{
        color:#fff;
        text-align:left;
    }
    .inquiry .inquiry_inner .title span{
        color: #fff;
        
    }
    .inquiry .inquiry_inner .enquiry {
        text-align: center;
        font-size: 1em;
    }
    .inquiry .inquiry_inner .enquiry input[type=text], input[type=number] {
        padding: 20px 20px;
        font-size: 0.875em;
        font-family: "Open Sans", sans-serif;
        background-color: #fff;
        border: none;
        outline: 0;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        border-radius: 5px;
    }
    ::placeholder {
        color: #cccccc;
        font-style:italic;
    }
    textarea::placeholder {
        color: #cccccc;
        font-family: "Open Sans", sans-serif;
        padding: 7px 13px;
        font-size: 0.875em;
    }
    .inquiry .inquiry_inner .enquiry input[type=email]{
        padding: 20px 20px;
        font-size: 0.875em;
        font-family: "Open Sans", sans-serif;
        background-color: #fff;
        border: none;
        outline: 0;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        border-radius: 5px;
    }
    .inquiry .inquiry_inner .enquiry input[type=submit]{
        clear: both;
        display: block;
        padding: 20px 60px;
        font-size: 0.875em;
        color: #fff;
        font-family: "Open Sans", sans-serif;
        background-color: #603d99;
        border: none;
        outline: 0;
        box-sizing: border-box;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        margin: 0 auto;
        font-weight:500;
    }
    .inquiry .inquiry_inner .enquiry .name{
        float: left;
        width: 47%;
    } 
    .inquiry .inquiry_inner .enquiry .email {
        float: left;
        clear: both;
        margin-top: 28px;
        width: 47%;
    }
    .inquiry .inquiry_inner .enquiry .phone {
        float: left;
        clear: both;
        width: 47%;
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .inquiry .inquiry_inner .enquiry textarea {
        width: 50%;
        height: 237px;
        border: 1px solid #4acaa9;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 18px;
        resize: none;
    }

    .product_html{
        padding: 25px 50px;
    }
    .product_html p {
        font-size: 22px;
        font-weight: 900;
        color: #333;
        padding-bottom: 20px;
    }
    .product_html .html_inner{
        border: 1px solid #ccc;
        padding: 50px 15px;
    }
    
    input[type="number"]::-webkit-inner-spin-button, 
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="number"]::-ms-clear {
        display: none;
    }

    @media only screen and (max-width: 900px){
        .inquiry .inquiry_inner .enquiry .name,
        .inquiry .inquiry_inner .enquiry .email,
        .inquiry .inquiry_inner .enquiry .phone,
        .inquiry .inquiry_inner .enquiry textarea{
            float: none;
            width: 100%;   
        }
        .product_box{
            flex-wrap: wrap;
            justify-content: center;
        }
        .product_inner_cover .right{
            text-align:center;
        }
        .product_box table th{
            text-align:right;
        }
        .events{
            text-align:center;
        }
        .product_inner_cover .left .img_box{
            width:100%;
        }
        .product_inner_cover .left{
            width:auto;
        }
        .left ul{
            width:100%;
        }
        .inquiry .inquiry_inner .enquiry input[type=submit]{
            margin-top: 15px;
            width: 100%;
        }
        .sangse{
            flex-direction: column;
            align-items: center;
        }
        .title h3{
            font-size: 22px;
        }
        .product_content_info .start{
            font-size: 18px;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 500px){
        .product_inner_cover .right{
            padding: 10px;
        }
        .product_inner{
            padding: 130px 5px;
        }
        .product_html{
            padding: 25px 10px;
        }
        .product_inner_cover .right table{
            font-size:12px;
        }
        .product_inner_cover .right h4{
            font-size:20px;
        }
    }
    
`;



export const Product = ()=>{
    
    
    const secretKey = "product_idx";
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const idx = fromUrlSafeBase64(query.get('p'));
    const bytes = CryptoJS.AES.decrypt(idx, secretKey);
    const product_idx = Number(bytes.toString(CryptoJS.enc.Utf8));

    const [view, setView] = useState({});
    const [selectImg, setSelectImg] = useState("");
    const [load,setLoad] = useState(false);
    
    const [formObj, setFormObj] = useState({
        site_id : getCookie("site_id"),
        username : "",
        email : "",
        phone : "",
        msg_content: "",
        msg_title: ""
    });

    useEffect(()=> {
        const getProduct = async ()=> {
            try{

                const res = await axios.post(API + "/product/getProduct", {product_idx, site_id : getCookie("site_id")});
                setView(res.data);
                const url = `${S3}/${getCookie("folder")}/product/${res.data.imgList.find(img => img.img_main === 1)?.img_url}`;
                setSelectImg(url);
                setFormObj((prev) => prev={...prev, msg_title : `${res.data.title} 관련 문의합니다.`})
            }catch(e){
                setView(null);
            }
        }
        getProduct();
    }, [product_idx]);
    const alertFunction = (e) =>{
        e.preventDefault();

        const setMsg = async ()=> {
            setLoad(true);
            const res = await axios.post(API + "/msg/setMsg", formObj)

            if(res.data === "SUCCESS"){
                alert("문의하기가 완료되었습니다. \n빠른시일내에 연락드리겠습니다.");
                setLoad(false);
                window.location.reload();
            } else {
                alert("문의하기 준비중입니다");
            }
        }
        setMsg();
    }

    if(view == null){
        return <NotFound></NotFound>
    }
    
    return <>
        {(load == true) && (
            <Loading/>
        )}
        <ProductStyle>
            <div className="product_inner">
                <div className="product_inner_cover">
                    <div className="product_box">
                        <div className="left">
                            <div className="img_box">
                                <img src={selectImg} alt={view.title} />
                            </div>
                            <ul>
                            {view.imgList && view.imgList.sort((a, b) => b.img_main - a.img_main).map((item, idx) => (
                                <li key={idx} className={(idx === 0) ? "active" : ""} onMouseEnter={(e)=>{
                                    const par = e.currentTarget.parentElement.querySelectorAll("li");
                                    par.forEach((elem, i) => {
                                        elem.classList.remove("active");
                                    })
                                    e.currentTarget.classList.add("active");
                                    const url = `${S3}/${getCookie("folder")}/product/${item.img_url}`;
                                    setSelectImg(url);
                                }}>
                                    <img src={`${S3}/${getCookie("folder")}/product/${item.img_url}`} alt="" />
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="right">
                            {view?.state == 1 && (
                                <div className="saleStop">
                                    <span>수업이 종료되었습니다.</span>
                                </div>
                            )}
                            <div className="title">
                                <h4>{view.title}</h4>
                            </div>
                            
                            <table>
                                <thead>
                                    <tr>
                                        <th>훈련비</th>
                                        <th>
                                            {view.amount == 0 && view.comment != null ? (
                                                <p className="events">{view.comment}</p>
                                            ) : (
                                                <>
                                                <p className="sale">{parseInt(view.amount).toLocaleString()}원</p>
                                                </>
                                            )}
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <th>자비부담액</th>
                                        <td>
                                        {view.amount == 0 && view.comment != null ? (
                                            
                                            <span className="events">{view.comment}</span>
                                        ) : (
                                            <span className="events">{(view.event == 100) 
                                                ? "전액무료" : (view.amount - (view.amount * view.event / 100)).toLocaleString()+"원"}</span>
                                        )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>수업기간</th>
                                        <td>
                                            {view.start_date && view.end_date ? (
                                                <>
                                                <span>{view.start_date}</span> <span> ~ </span> <span>{view.end_date}</span>
                                                </>
                                            ) : (
                                                <>
                                                <span>{(view.comment != "") ? view.comment : "미정"}</span>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="btn">
                                <button type="button" onClick={()=>{
                                    const inquiry = document.querySelector(".inquiry");
                                    const h = inquiry.offsetTop;

                                    window.scrollTo({
                                        top:h,
                                        behavior: "smooth"
                                    })


                                }}>
                                    신청하기
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="product_html">
                        <p>상세내용</p>
                        <div className="html_inner">
                            <div className="sangse" style={{ display: "flex" }}>
                                <div className="ck ck-editor__main" 
                                style={{ width : "100%"}}>
                                <div
                                    className="ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred"
                                    dangerouslySetInnerHTML={{ __html: transformContent(view.content) }} // 결과 확인 
                                />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="inquiry">
                <div className="inquiry_inner">
                    <div className="title">
                        <h3>CONTACT US</h3>
                        <span>편하게 문의 하세요!</span>
                    </div>
                    <form onSubmit={alertFunction}>
                        <div className="enquiry">
                            <input type="text" value={formObj.username} onChange={(e)=>setFormObj({...formObj,username:e.target.value})} placeholder="이름을 입력하세요!*" className="name" required />
                            <input type="email" value={formObj.email} onChange={(e)=>setFormObj({...formObj,email:e.target.value})} placeholder="이메일을 입력하세요!*" className="email" required />
                            <input type="text" maxLength={11} value={formObj.phone} onChange={(e)=>{
                                const newValue = e.target.value;
                                if (!isNaN(newValue) || newValue === '') {
                                    setFormObj({...formObj, phone: newValue});
                                }
                            }} placeholder="연락처를 입력하세요 (숫자만입력)*" className="phone" required />
                            <textarea className="message" value={formObj.msg_content} onChange={(e)=>setFormObj({...formObj,msg_content:e.target.value})} placeholder="YOUR MESSAGE*" ></textarea>
                            <input type="submit" value={"SEND MESSAGE"} />
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="product_inner">
                <div className="product_inner_cover">
                    <div className="product_content">
                        
                        <div className="product_content_info">
                            <div className="title">
                                <h3><div className="sangse_img"><img src={view.img} alt={view.title} /></div>{view.title}</h3>
                                <div className="product_content_inner">
                                    {view.event > 0 ? (
                                        <div className="amount">
                                            <span className="events">{(view.comment == null) ? "미정" : view.comment}</span>
                                            
                                        </div>
                                    ) : (
                                        <div className="amount">
                                            <span className="events">{(view.comment == null) ? "미정" : view.comment}</span>
                                            
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="sangse">
                                <p>수업 설명</p>
                                <div className="sangse_txt">{view.content}</div>
                            </div>
                            <div className="calendar">
                                <p>수업 일정 : <span className="events">{(view.dayArr == 0) ? (view.comment == null) ? "미정" : view.comment : startDay + " ~ " + endDay}</span></p>

                                <Calendar dayArr={view.dayArr} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="inquiry">
                    <div className="inquiry_inner">
                        <div className="title">
                            <h3>CONTACT US</h3>
                            <span>편하게 문의 하세요!</span>
                        </div>
                        <form onSubmit={alertFunction}>
                            <div className="enquiry">
                                <input type="text" value={formObj.name} onChange={(e)=>setFormObj({...formObj,name:e.target.value})} placeholder="YOUR NAME*" className="name" required />
                                <input type="email" value={formObj.email} onChange={(e)=>setFormObj({...formObj,email:e.target.value})} placeholder="YOUR E-MAIL*" className="email" required />
                                <input type="text" value={formObj.phone} onChange={(e)=>setFormObj({...formObj,phone:e.target.value})} placeholder="YOUR PHONE*" className="phone" required />
                                <textarea className="message" value={formObj.message} onChange={(e)=>setFormObj({...formObj,message:e.target.value})} placeholder="YOUR MESSAGE*" ></textarea>
                                <input type="submit" value={"SEND MESSAGE"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
        </ProductStyle>
    </>
}