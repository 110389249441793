import { useEffect, useRef } from "react"
import { Nav, NavTab } from "./Nav"
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const HeaderStyle = styled.div`
#header{
	z-index: 10;
	width: 100%;
	height: 80px;
	background-color: #fff;
	position: fixed;
	left:0;
	top:0;
	font-size: 1em;
	overflow: hidden;
	transition: all 0.6s;
}

/* PC */

#header.fixed{
	box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, .2);
}

#header.height {
	height: calc(var(--vh, 1vh) * 50);
	box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, .2);
}
#header::before{
	content: "";
	position: absolute;
	display: block;
	left:0;
	top: 79px;
	width: 100%;
	height: 1px;
	background-color: #dddddd;
}
#header.fixed::before{
	top: 79px;
}
#header .header_inner {
	position:relative;
	margin: 0 auto;
	max-width: var(--max-width);
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 25px;
}

#header .header_inner .logo a{
	display: block;
	color: #333;
	width: 222px;
	font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: center;
}
#header .header_inner .logo a .logo_cover{
	width: 80px;
}
#header .header_inner .logo a img{
	width: 100%;
}
#header.fixed .header_inner .logo a,
#header.height .header_inner .logo a{
	color: #333;
}
#header .header_inner .logo a span.detail_logo_color{
	color: var(--main-color);
	font-size: 1.3em;
	display: inline-block;
	transform: rotate(-9deg);
}
#header .header_inner .util li {
	display: flex;
	width: 88px;
	height: 36px;
	padding-top: 6px;
	background-color: #A63145;
	border-radius: 20px;
	text-align: center;
	box-sizing: border-box;
}
#header .header_inner  .util li:nth-child(2) {
	background-color: #595757;
}
#header .header_inner  .util li a {
	display: block;
	color: #333;
	font-size:	0.813em;
}
#header .header_inner  .util li a img {
	vertical-align: middle;
	margin-right: 6px;
}
#header .header_inner .menu_bar {
	width: 23px;
	height: 16px;
	background-color: transparent;
	cursor:pointer;
}
#header .header_inner .menu_bar .menu_bar_inner{
	position: relative;
	width: 23px;
	height: 16px;
}
#header .header_inner .menu_bar .menu_bar_inner::after{
	content: "";
	display: block;
	position: absolute;
	top:0;
	left:0;
	width: 23px;
	height: 2px;
	background-color: #333;
	transition: 0.3s;
}
#header .header_inner .menu_bar:hover .menu_bar_inner::after{
	transform: rotate(45deg);
	top: 3px;
	width: 8px;
	left: 15px;
}
#header .header_inner .menu_bar .menu_bar_inner::before{
	content: "";
	display: block;
	position: absolute;
	bottom:0;
	left:0;
	width: 23px;
	height: 2px;
	background-color: #333;
	transition: 0.3s;
}
#header .header_inner .menu_bar:hover .menu_bar_inner::before{
	transform: rotate(-45deg);
	bottom: 3px;
	width: 8px;
	left: 15px;
}
#header .header_inner .menu_bar .menu_bar_inner a {
	display:block;
	width: 23px;
	height: 16px;
}
#header .header_inner .menu_bar .menu_bar_inner span {
	position:absolute;
	right:0;
	top: 7px;
	display: block;
	width: 18px;
	height: 2px;
	background-color: #333;
}
/*#header .header_inner .menu_bar:hover .menu_bar_inner span{
	right: 1px;
	width: 23px;
}*/

#header.height .header_inner .menu_bar .menu_bar_inner::after,
#header.height .header_inner .menu_bar .menu_bar_inner::before,
#header.height .header_inner .menu_bar .menu_bar_inner span,
#header.fixed .header_inner .menu_bar .menu_bar_inner::after,
#header.fixed .header_inner .menu_bar .menu_bar_inner::before,
#header.fixed .header_inner .menu_bar .menu_bar_inner span{
	background-color: #000;
}

#header .header_inner .main-menu > ul {
	position: relative;
	display: flex;
	gap: 90px;
	justify-content: start;
	align-items: center;
}
#header .header_inner .main-menu > ul li a {
	font-size: 15px;
	font-weight: 500;
}
#header .header_inner .main-menu > ul > li {
	display: inline-block;
	position	:relative;
}
#header .header_inner .main-menu > ul > li > a {
	display:block;
	line-height: 3.5;
	color : #333;
	font-size:1.125em;
}
#header.height .header_inner .main-menu > ul > li > a,
#header.fixed .header_inner .main-menu > ul > li > a {
	color : #000;
}
#header .header_inner .main-menu > ul ul {
	position: absolute;
	left:0;
	top: 80px;
	width: auto;
	min-width: 100%;
	text-align: center;
	z-index: 200;
	/*height: calc(var(--vh, 1vh) * 50);*/
	padding: 20px 0;
}
/*
#header.height .header_inner .main-menu > ul ul{
	transition-delay: 0.3s;
	height: calc(var(--vh, 1vh) * 50);
	padding: 20px 0;
}
*/
#header .header_inner .main-menu > ul ul li a {
	display:block;
	line-height: 2.4;
	color : #666666;
	font-size: 14.5px;
}
#header .header_inner .main-menu > ul > li::before {
	content: "";
	position: absolute;
	display: block;
	left:50%;
	top: 60px;
	width: 0;
	height: 1px;
	background-color:#a63145;
	transition: .4s;
}
#header .header_inner .main-menu > ul > li:hover::before {
	left:0;
	width: 100%;
}
#header .header_inner .main-menu > ul li li:hover a,
#header .header_inner .main-menu > ul > li:hover > a{
	color:#a63145;
}
/***********************pc_tap**********************/
.tab{
	display: none;
}
.tab.active{
	display: block;
}
.tab_menu-cover {
	position: fixed;
	right:0;
	top:0;
	width:100%;
	height: 100vh;
	background-color: #000;
	opacity: 0.5;
	z-index: 1002;
	display:block;
}
.tab_menu-cover.active{
	display:block;
}
.tab_menu {
	display: none;
	display: block;
	position: fixed;
	right:0;
	top:0;
	width: 100%;
	height: 100vh;
	z-index: 1003;
	font-size:1em;
	overflow-y: auto;
	padding: 100px 25px;
}
.tab_menu.active{
	display: block;
}
.tab_menu h3 {
	width: 100%;
	text-align: center;
	font-size:2.813em;
	color: #fff;
}
.tab-menu-nav > ul {
	max-width: var(--max-width);
	margin: 0 auto;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}
.tab-menu-nav ul li a {
	color :#fff;
	display:block;
}
.tab-menu-nav > ul > li {
	position: relative;
	width: calc((100% - 40px) / 3);
	text-align:center;
	font-size: 1.875em;
	font-weight: 700;
	padding-top:32px;
}

.tab-menu-nav > ul > li .menu_title {
	position: relative;
}
.tab-menu-nav > ul > li > a > span{
	position: relative;
}
.tab-menu-nav > ul > li > a > span::before{
	content: "";
	display: block;
	position: absolute;
	left:0;
	bottom: 0;
	width:  0;
	height: 14px;
	background-color:#a63145;
	transition: width 0.3s;
	z-index: -1;
}
.tab-menu-nav > ul > li:hover > a > span::before{
	width: 100%;
}
.tab-menu-nav > ul > li > a {
	padding-bottom: 23px;
	z-index: 1005;
}
.tab-menu-nav ul ul {
	width: 100%;
	text-align:center;
	font-size:0;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
.tab-menu-nav ul ul li {
	width: calc((100% - 10px) /2);
	height: 37px;
	background-color: #1A1E24;
}
.tab-menu-nav ul ul li:nth-child(odd):last-child{
	width: 100%;
}

.tab-menu-nav ul ul li a{
	display: block; 
	font-size: 15px;
	padding-top:6px;
}
.tab-menu-nav ul ul li:hover a{
	color:#a63145;
}
.tab_menu .menubar-close{
	position:fixed;
	right:20px;
	top:0;
	width:100px;
	height: 100px;
	background-color: #000;
	cursor: pointer;
}
.tab_menu .menubar-close .menubar-close-inner{
	width: 100%;
	height: 100%;
}
.tab_menu .menubar-close .menubar-close-inner .menu_close_btn_cover{
	display: flex;
	width:100%;
	height:100%;
	justify-content: center;
	align-items: center;
	border: 1px solid #262626;
}
.tab_menu .menubar-close .menubar-close-inner span {
	position: absolute;
	display: block;
	height: 2px;
	width: 36px;
	background-color: #fff;
	transform: rotate(-45deg);
}
.tab_menu .menubar-close .menubar-close-inner .menu_close_btn_cover::before{
	content: "";
	display:block;
	position: absolute;
	display: block;
	height: 2px;
	width: 36px;
	background-color: #fff;
	transform: rotate(45deg);
}



@media only screen and (max-width : 1200px){
	.tab_menu-cover{
		opacity: 1;
	}
	#header .header_inner .main-menu{
		display:none;
	}
	#header.height{
		height: 80px;
	}
}

@media only screen and (max-width : 900px){
	.tab-menu-nav > ul > li{
		width: calc((100% - 20px)/2);
	}
}
@media only screen and (max-width: 650px){
	.tab-menu-nav > ul > li{
		width: 100%;
	}
}
`
export const Header = ()=>{
	const tabRef = useRef(null);
	const menuRef = useRef(null);
	const history = useLocation();


	const tabReset = ()=>{
		const elem = tabRef.current;
		const body = document.querySelector("body");
		body.classList.remove("hidden");
		elem.classList.remove("active");
	}
	const tabHandler = (e) =>{
		const elem = tabRef.current;
		const body = document.querySelector("body");
		body.classList.toggle("hidden");
		elem.classList.toggle("active");
	}
	useEffect(()=>{
		tabReset();
		
		const header = menuRef.current;

		const mouseUp = ()=>{
			header.classList.add("height");
		}
		const mouseOut = ()=>{
			header.classList.remove("height");
		}
		
		


		header.addEventListener("mouseenter", mouseUp);
		header.addEventListener("mouseleave", mouseOut);

		return ()=>{
			header.removeEventListener("mouseenter", mouseUp);
			header.addEventListener("mouseleave", mouseOut);
		}
		
	}, [menuRef, tabRef,history])
    return <HeaderStyle>
	
		<header id="header" ref={menuRef}>
			<div className="header_inner">
				<div className="logo">
					{/* <h1><a href="/"><span>I</span><span>T</span><span className="detail_logo_color">P</span>학원</a></h1> */}
					<h1>
						<a href="/" style={{fontSize:"10px"}}>
							<div className="logo_cover">
								<img src={require("../../../../resources/images/logo.png")} alt="logo" />
							</div>
							<span style={{fontSize: "14px"}}>경기<span style={{color:"var(--main-color)", fontSize: "16px"}}>IT</span>프로그래밍학원</span>
						</a>
					</h1>
				</div>
				<Nav className="main-menu"/>
				
				<div className="menu_bar" onClick={tabHandler}>
					<div className="menu_bar_inner">
						<span className="menu_icon"></span>
					</div>
				</div>
			</div>
			<div className="menu_height"></div>
		</header>
		<div className="tab" ref={tabRef}>
			<div className="tab_menu-cover"></div>
			<div className="tab_menu">
				<h3>사이트맵</h3>
				<Nav className="tab-menu-nav" />
				<div className="menubar-close" onClick={tabHandler}>
					<div className="menubar-close-inner">
						<div className="menu_close_btn_cover"><span className="menu_icon-close"></span></div>
					</div>
				</div>
				<div className="line_cover">
					<div className="line"></div>
				</div>
			</div>
		</div>
	</HeaderStyle>
}