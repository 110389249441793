
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Page1 } from './content/Page1';
import { Lecture } from './content/Lecture';
import { LoadMap } from './content/LoadMap';
import { Contact } from './content/Contact';
import { useEffect, useRef, useState } from 'react';
import { Controller } from '../../component/Controller';
import sliderImage1 from "../../../resources/images/slide2.jpg";
import lobotData from "../../../resources/lottie/robot/Animation - 1699590188462.json";
import lottie from "lottie-web";
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import { Popup } from '../../component/Popup';
import styled, { createGlobalStyle } from 'styled-components';

const MainStyle = createGlobalStyle`

body{
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
}
body.mobile{
    height: 100vh;
    overflow: auto;
}
.title{
	padding: 0 0 50px 0;
}
.title h3 {
    font-size: 2.813em;
	font-weight: 400;
    color: var(--font-color-title);
}

.title h3 .content_title {
    position: relative;
    color: var(--font-color-title);
}
.title span{
    font-size: 1.125em;
    color: #6E6E6E;
    font-weight: 400;
}
.title h3 .content_title::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 3px;
    height: 13px;
    transform: rotate(45deg);
    background-color: var(--main-color);
}
.title.center {
    text-align: center;
}
.title.center h3{
    display: inline-block;
}
.title.center h3::before{
    left: 45%;
    top: -5px;
}
.swiper-wrapper{
    -webkit-user-select: none;
 -moz-user-select: none;
 -ms-use-select: none;
 user-select: none;
}
#keyvisual .keyvisual-pagination{
    position:absolute;
    left: 0;
    bottom: 100px;
    width: 100%;
    z-index: 6;
}
#keyvisual .keyvisual-pagination .keyvisual-pagination_cover{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}
#keyvisual .swiper-pagination{
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding: 0 25px;
    bottom: 100px;
}
#keyvisual .swiper-pagination-bullet{
    width: 50px;
    height: 10px;
    background-color: var(--main-color);
    border-radius: 0;
    opacity: 0.6;
}
#keyvisual .swiper-pagination-bullet-active{
    opacity: 1;
}
.controller{
    position: fixed;
    right: 15px;
    top: 50%;
    z-index: 9;
    color: #fff;
    text-shadow: #000;
    -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.controller li{
    display: block;
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    padding-right: 20px;
    text-align: right;
    font-size: 12px;
    text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333;
}
.controller.pc li{
    font-size: 18px;
}
.controller li::after{
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #666;
    border-radius: 50px;
    right: 0;
    top: calc((100% - 10px)/2);
    
}
.controller.pc li.active::after,
.controller.pc li:hover::after{
    background-color: var(--main-color);
    color: var(--main-color);
}
.controller.pc li.active,
.controller.pc li:hover{
    color: var(--main-color);
}
.controller li:first-child{
    margin-top: 0;
}


.controller.mobile{
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0 15px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 1px 0px 20px 1px rgba(0, 0, 0, .2);
}
.controller.mobile ul{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.controller.mobile li{
    text-align: center;
    padding-right: 0;
    margin: 0;
    padding: 10px 0;
    width: 100%;
}
.controller.mobile li.active{
    text-shadow: var(--main-color) 0px 0 4px;
    color: #333;
    font-weight: 900;
}
.controller.mobile li span{
    display: inline;
}

/* .controller.mobile li:first-child{
    display: none;
} */
.controller.mobile li::after{
    display: none;
}
.mouse_btn{
    position: absolute;
    bottom: 20px;
    display: flex;
    
    color: #fff;
    justify-content: center;
    z-index: 9;
    width:100%;
}
.mouse_btn .mouse_cover{
    display: flex;
    flex-flow: column;
    -ms-flex-flow: column;
    height: 50px;
}

.mouse_btn .mouse_cover li{
    width: 50px;
    height: 12px;
    position: relative;
    animation: mouse_arrow 3s ease 0s infinite normal none running;
    
}

.mouse_btn .mouse_cover li::before,
.mouse_btn .mouse_cover li::after{
    content:"";
    display: block;
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #fff;
    top: 0;
}
.mouse_btn .mouse_cover li::before{
    transform: rotate(25deg);
    top: 0;
}
.mouse_btn .mouse_cover li::after{
    transform: rotate(-25deg);
    right: 2px;
}



@keyframes mouse_arrow {
	0%{
		
        top: 0;
	}
	50%{
        top: 10px;
	}
    
	100%{
        top: 0;
    }
}

.container{
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1em;
    position: relative;
    overflow: hidden;
}
.container.tablet{
    height: 100vh;
}

.container .container_inner{
    height: 100%;
    width: 100%;
    position: relative;
}
.container:not(#keyvisual) .container_inner{
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 100px 25px 50px;
    height: auto;
}
.swiper{
    width: 100%;
    height: 100%;
}
#keyvisual .keyvisual_detail{
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 25px;
    background-color: rgba(0,0,0,0.5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
#keyvisual .keyvisual_background_imgae{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
}
#keyvisual .swiper-slide.swiper-slide-active .keyvisual_background_imgae{
    transform: scale(1.2);
    transition: transform 3s;
}
#keyvisual .animation,
#keyvisual .animation_list{
    opacity: 0;
    transform: translateY(50px);
}
#keyvisual h2 {
    font-size: 2em;
}
#keyvisual .swiper-slide.swiper-slide-active .animation{
    transform: translateY(0);
    opacity: 1;
    transition: transform 3s, opacity 1s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation:nth-child(1){
    transition-delay: 0.3s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation:nth-child(2){
    transition-delay: 1s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation:nth-child(3){
    transition-delay: 1.5s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation:nth-child(4){
    transition-delay: 2s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation:nth-child(5){
    transition-delay: 2.5s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list{
    transform: translateY(0);
    opacity: 1;
    transition: transform 3s, opacity 1s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list:nth-child(1){
    transition-delay: 3s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list:nth-child(2){
    transition-delay: 3.5s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list:nth-child(3){
    transition-delay: 4s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list:nth-child(4){
    transition-delay: 4.5s;
}
#keyvisual .swiper-slide.swiper-slide-active .animation_list:nth-child(5){
    transition-delay: 5s;
}
/* #keyvisual .keyvisual_detail.key01::after{
    content: '';
    display: block;
    position: absolute;
    background: url('../../images/man-320276_1280.png') no-repeat center bottom;
    background-size: contain;
    left: 0;
    top: 80px;
    width: 100%;
    height: 100%;
    transform: perspective(500px) rotateY(-10deg);
    z-index: 5;
    opacity: 1;
    transition: all 2s;
} */
#keyvisual .swiper-slide.swiper-slide-active .keyvisual_detail.key01::after{
    opacity: 0.5;
    transform: perspective(500px) rotateY(0deg);
    
}


#keyvisual .swiper-slide .keyvisual_detail::before{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(255,255,255,0.2);
    z-index: 4;
    
}
.container .keyvisual_detail .keyvisual_text{
    font-size: 2.38em;
    text-align: left;
    color: #fff;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    z-index: 1;
    padding: 0 30px;
    z-index: 6;
}
.container .keyvisual_detail .keyvisual_text p{
    margin: 15px 0;
    text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333;
    color: #EFFF00;
}
.container .keyvisual_detail .keyvisual_text h2{
    text-shadow: -1px 0 #333, 0 1px #333, 7px 0 #333, 0 -1px #333;
}
.container .keyvisual_detail .keyvisual_text.center{
    text-align: center;
}
.container .keyvisual_detail .keyvisual_text .special{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-top: 20px;
    font-size: 0.421em;
    gap: 40px;
    text-align: center;
}
.container .keyvisual_detail .keyvisual_text .special.card-section{
    padding-top: 100px;
}
.container .keyvisual_detail .keyvisual_text .special li{
    display: flex;
    align-items: center;
    justify-content: center;
}
.container .keyvisual_detail .keyvisual_text .special.card-section li{
    padding: 25px 10px;
    border-radius: 20px;
    background-color: var(--main-color2);
    color: #fff;
    width: 230px;
    height: 250px;
    align-items: start;
}
.container .keyvisual_detail .keyvisual_text .special.card-section li dl{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.container .keyvisual_detail .keyvisual_text .special.card-section li dd{
    font-size: 1.01em;
    font-weight: 900;
}
.container .keyvisual_detail .keyvisual_text .special li dt{
    font-size: 1.200em;
    font-weight: 900;
    padding-bottom: 10px;
}
.container .keyvisual_detail .keyvisual_text .special.toggle li{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background: radial-gradient(circle, #048dcf, #333);
}

.container .keyvisual_detail .keyvisual_text .special.card li{
    background: url("../../images/circuit.png") no-repeat center center;
    background-size: cover;
    border: 2px dotted #20c997;
    padding: 15px 10px;
    position: relative;
}
.container .keyvisual_detail .keyvisual_text .special.card li::after{
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,0.6);
}
.container .keyvisual_detail .keyvisual_text .special.card li::before{
    content: "";
    display:block;
    position: absolute;
    width: 1px;
    height: 50px;
    border: 1px dotted #20c997;
    bottom: -50px;
    border-bottom: none;
}
.container .keyvisual_detail .keyvisual_text .special.card li dl{
    z-index: 2;
}
.container .keyvisual_detail .keyvisual_text .special.card li dt{
    padding-bottom: 0;
}
.container .keyvisual_detail .keyvisual_text .special_cover{
    max-width: 500px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}
.container .keyvisual_detail .keyvisual_text .partition{
    width: 100%;
    font-size: 0.7em;
    margin-top: 48px;
    padding: 15px 10px;
    
    display: inline-flex;
    position: relative;
    border: 2px dotted #20c997;
    justify-content: center;
}

.container .keyvisual_detail .keyvisual_text .partition span{
    z-index: 3;
    font-weight: 900;
}
.container h3{
    font-size: 2.813em;
	color: var(--font-color-title);
	padding-bottom: 18px;
    position: relative;
}
.container h3::before{
    content:"";
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 3px;
	height: 13px;
	transform: rotate(45deg);
	background-color: var(--main-color);
}

#contents .contents{
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}
#contents .contents .category::before{
    content:"";
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 1px;
	background-color: #000000;
}
#contents .contents .category{
    width: 20%;
    position:relative;
}
#contents .contents .category li{
    width: 100%;
    padding-left: 13px;
	box-sizing: border-box;
	line-height: 3;
	position: relative;
	cursor: pointer;
}
#contents .contents .category li.active, 
#contents .contents .category li:hover{
    color: var(--main-color);
}
#contents .contents .category li.active::after{
    background-color: var(--main-color);
}
#contents .contents .category li::before{
    content:"";
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: #DDDDDD;
}
#contents .contents .category li::after{
    content:"";
	display: block;
	position: absolute;
	right: 0;
	top: 50%;
	width: 10px;
	height: 3px;
	background-color: #CDCDCD;
}




#contents .contents .content{
    padding: 20px;
    margin-left: 20px;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    
}
#contents .content_cover{
    width: 300px;
    margin-left: 20rem;
    height: 400px;
}
#contents .contents .content::before{
    content: "";
    background-color: #f5f5f5;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    border-radius: 20px 0 0 20px;
    height: 100%;
    right: -37rem;
}
#contents .swiper-backface-hidden .swiper-slide{
    position: relative;
    
}
#contents .view_content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    transition: all 1s;
    
    left: 0;
    opacity: 1;
    cursor: pointer;
}

#contents .view_content a{
    color: #333;
    display: block;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: self-start;
    padding: 40px 25px;
    height: 100%;
    width: 100%;
}
#contents .swiper-slide-active .view_content a{
    color: #fff;
}
#contents .swiper-slide:last-child{
    display: flex;
    align-items: center;
}
#contents .swiper-slide:last-child .view_content{
    border-radius: 50%;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    position: static;
}

#contents .view_content.slide-out, #contents .swiper-slide-prev .view_content{
    opacity: 0;
    transition: all 0s;
}
#contents .view_content_txt{
    width: 100%;
}
#contents .view_content_txt h4{
    font-size: 28px;
}
#contents .swiper-pagination{
    left: -8rem;
    bottom: -15px;
}
#contents .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    
}
#contents .swiper-pagination-bullet:last-child{
    display: none;
}
#contents .swiper-pagination-bullet-active{
    background-color: var(--main-color2);
}


#contents .view_content_txt p{
    padding-top: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

#contents .swiper-slide-active .view_content_txt p{
    display: -webkit-box;
}
#contents .icons-cover{
    width: 80px;
    height: 80px;
    background-color: #f5f5f5;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #666;
}


#contents .swiper{
    overflow: visible;
}
#contents .swiper-slide-active .view_content{
    background-color: var(--main-color2);
    left: -8rem;
    top: -20px;
    
}

#contents .swiper-slide-active .icons-cover{
    background-color: var(--main-color2);
    color: #fff;
    box-shadow: 7px 8px 10px 9px rgba(0, 0, 0, .2);
}


#lecture {
    /* background: url('../../images/page5bg1.jpg') no-repeat center center; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#lecture .title h3{
    color: #fff;
    font-size: 2.813em;
    font-weight: 900;
}
#lecture .title span{
    color: #fff;
}
#lecture .slide_title{
    width: 162px;
}

#lecture .slide_title img{
    width: 100%;
}
#lecture .slide_title h5{
    font-size: 1.375em;
    color: #fff;
    margin-top: 21px;
    display: flex;
    align-items: center;
}
#lecture .slide_title h5 span{
    margin-left: auto;
    font-size: 0.7em;
}
#lecture .swiper{
    overflow: visible;
}
#lecture .slide_cover{
    display: flex;
}
#lecture .slide_cover .slide_contents{
    margin-left: 40px;
    font-size: 1.1em;
    color: #fff;
    font-weight: 300;
}
#lecture .slide_cover .slide_contents dt{
    font-size: 1.125em;
    font-weight: 400;
    -webkit-line-clamp: 1;
    
}
#lecture .slide_cover .slide_contents dd{
    padding-top: 20px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
}
#lecture .swiper-button-prev, #lecture .swiper-button-next{
    top: -40px;
    left: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #fff;
}
#lecture .swiper-button-prev::after,
#lecture .swiper-button-prev::before,
#lecture .swiper-button-next::after,
#lecture .swiper-button-next::before{
    content: "";
    display: block;
    position: absolute;
    bottom: 14px;
    left: 19px;
    height: 8px;
    width: 2px;
    background-color: #fff;
    
}
#lecture .swiper-button-prev::after{
    transform: rotate(-45deg);
}
#lecture .swiper-button-prev::before{
    top: 14px;
    transform: rotate(45deg);
}

#lecture .swiper-button-next::after{
    transform: rotate(-45deg);
    top: 14px;
}
#lecture .swiper-button-next::before{
    transform: rotate(45deg);
}



#lecture .swiper-button-next{
    left: 50px;
}
#lecture .title{
    padding-bottom: 100px;
}



#loadMap{
    background-color: #f5f5f5;
}
.load_map_cover{
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    padding: 0 15px;
}
.load_map_cover .load_txt{
    position: absolute;
    right: 15px;
    bottom: -10px;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: 5px 3px 15px 0px rgba(0, 0, 0, .2);
}




#contact{
    align-items: flex-end;
}
#contact .contents_tell{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
#contact .contents_tell svg{
    color: var(--main-color);
}
#contact .contents_tell li{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #696868;
    font-size: 1.125em;
}
/* #contents .swiper-slide:last-child .view_content .view-more::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-image: url('../../images/circuit.png') 30 / 19px round;
} */
#contents .swiper-slide:last-child .view_content .view-more{
    display: flex;
    align-items: center;
    position: relative;
}
#contents .swiper-slide:last-child{
    justify-content: start;
}




@media only screen and (max-width: 1100px){
    #contents .contents{
        flex-wrap: wrap;
    }
    #contents .contents .category{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    #contents .contents .category::after{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
    }
    #contents .contents .category li::after,
    #contents .contents .category li::before{
        display: none;
    }
    #contents .contents .category li{
        width: auto;
        padding: 0;
    }
    #contents .title{
        text-align: center;
    }
    #contents h3{
        display: inline-block;
        margin: 0 auto;
    }
    #contents .title span{
        display: block;
    }
    #contents .content_cover{
        width: 200px;
        height: 300px;
        margin-left: 0;
    }
    #contents .swiper-slide-active .view_content{
        left: 0;
        top: 0;
    }
    #contents .swiper-pagination{
        left: 0;
        bottom: -20px;
    }
    #contents .view_content_txt h4{
        font-size: 16px;
    }
    #contents .swiper-slide-active .view_content_txt p{
        font-size: 10px;
    }
    #contents .contents .content::before{
        right: 0;
        left: 0;
        top: 10px;
        border-radius: 20px;
    }
    #contents .swiper-slide:last-child .view_content{
        width: 100px;
        height: 100px;
        font-size: 50px;
    }
    #contents .contents .content{
        margin-left: 0;
    }
    .container .keyvisual_detail .keyvisual_text .special.card-section{
        flex-direction: column;
    }
    .container .keyvisual_detail .keyvisual_text .special.card-section li{
        width: 100%;
        height: auto;
        max-height: 110px;
    }
}
@media only screen and (max-width: 800px){
    .load_map_cover .load_txt{
        position: static;
        width: 100%;
    }
}
@media only screen and (max-width: 650px){
    #keyvisual .keyvisual-pagination .keyvisual-pagination_cover{
        text-align: center;
    }
    
    #keyvisual .keyvisual_detail.key01::after{
        top: 0;
        left: 0;
        background-position: -100px bottom;
    }
    .container .keyvisual_detail .keyvisual_text .special.card li{
        padding: 5px;
    }
    .controller li span{
        display: none;
    }
    .controller li::after{
        border: 2px solid rgba(0,0,0, 0.3);
    }
    .controller ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    #keyvisual{
        font-size: 0.4em;
    }
    .container .keyvisual_detail .keyvisual_text{
        padding: 0;
    }
    .container .keyvisual_detail .keyvisual_text .special.toggle li{
        width: 100px;
        height: 100px;
    }
	#contact .contents_tell{
        flex-wrap: wrap;
        gap: 20px;
    }
    #contact .contents_tell li{
        width: 100%;
        justify-content: center;
    }
    .container:not(#keyvisual) .container_inner{
        padding-top: 50px;
    }
    .container{
        font-size: 0.8em;
    }
    #lecture .slide_title{
        width: 112px;
    }
    #lecture .slide_cover .slide_contents dd{
        display: block
    }
    #lecture .slide_cover{
        gap: 30px;
        flex-wrap: wrap;
    }
    
    #lecture .slide_cover ul{
        line-height: 19px;
    }
    #lecture .slide_cover .slide_contents{
        width: 100%;
        margin: 0;
    }
    #lecture .slide_title h5 {
        margin-top: 28px;
    }
    #contents .swiper-pagination-bullet:last-child{
        display: inline-block;
    }
    #lecture {
        height: auto;
    }
    
}

@media only screen and (min-width : 600px) and (max-height: 800px) {
    .container.tablet{
        height: auto;
        min-height: 100vh;
        
    }
    #keyvisual.tablet{
        font-size: 0.4em;
    }
    .container.tablet .keyvisual_detail .keyvisual_text.center{
        padding: 50px 0;
    }
}
`

const keyvisual = [
    {
        style : {
            backgroundImage: `url(${sliderImage1})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            // backgroundColor: "blue",
            // opacity: 0.4
        }
    },
    {
        style : {
            backgroundImage: `url(${sliderImage1})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            // backgroundColor: "blue",
            // opacity: 0.4
        }
    }
]


export const Home = () =>{
    const [cnt, setCnt] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const pageRef = useRef(null);
    const [page, setPage] = useState([]);
    const startPage = 0;
    const endPage = page.length - 1; // 마지막 페이지 인덱스는 length - 1
    const swiperRef = useRef(null);
    const swiperLotiie = useRef(null);
    
    
        useEffect(()=>{
            lottie.loadAnimation({
                container: swiperLotiie.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: lobotData
            });
        }, [swiperLotiie])
        
        //const conRef = useRef(null)
        const animation = (header) => {
            if(isBrowser){
                if(isScrolling) return;
                if(cnt <= 0){
                    header.classList.remove("fixed");
                }else {
                    header.classList.add("fixed");
                }
                const height = window.innerHeight * cnt;
                
                
                window.scroll({
                    top: height,
                    behavior: "smooth",
                });


                setTimeout(()=>{
                    setIsScrolling(true);
                }, 300)
            }
        };
        
        const cntInit = (n)=>{
            if(n <= startPage){
                setCnt(0);
            } else if(n >= endPage){
                setCnt(endPage);
            }
        }

        
        useEffect(() => {
            
            setPage(Array.from(pageRef.current.querySelectorAll(".container")));
            const header = document.querySelector("#header");
            if(isBrowser){

            const resize = ()=>{
                
                setTimeout(()=>{
                    animation(header);
                    
                }, 1)
            }

            
            
            
            const keyEvent = (e)=>{
                setIsScrolling(false);
                cntInit(cnt);

                if(e.keyCode === 40){//Down
                    setCnt((prevCnt) => prevCnt + 1);
                } else if(e.keyCode === 38){
                    setCnt((prevCnt) => prevCnt - 1);
                }
            }
            const wheelEvent = (e) => {

                let currentElement = e.target;
                let isTarget = false;

                while (currentElement != null) {
                    if (currentElement.classList.contains('page_content_slide') 
                    || currentElement.classList.contains('tab')) {
                        //setIsScrolling(true);
                        isTarget = true;
                        
                        break;
                    }
                    currentElement = currentElement.parentElement;
                }
                


                if(isTarget) return;
                setIsScrolling(false);
                cntInit(cnt);
                
                
                if (e.deltaY >= 0) {
                    
                        setCnt((prevCnt) => prevCnt + 1);
                }
                else if (e.deltaY <= 0) {
                        setCnt((prevCnt) => prevCnt - 1);
                }
                
        
                    
            };

            

            // window.addEventListener("touchstart", onTouchStart);
            // window.addEventListener("touchmove", onTouchMove);
            // window.addEventListener("touchend", onTouchEnd);
            window.addEventListener("keydown", keyEvent);
            window.addEventListener("wheel", wheelEvent);
            window.addEventListener("resize", resize);
            

            return () => {
                window.removeEventListener("keydown", keyEvent);
                window.removeEventListener("wheel", wheelEvent);
                // window.removeEventListener("touchstart", onTouchStart);
                // window.removeEventListener("touchmove", onTouchMove);
                // window.removeEventListener("touchend", onTouchEnd);
                window.removeEventListener("resize", resize);
            };
            }
        }, [cnt, pageRef]);
    
        useEffect(() => {
            const header = document.querySelector("#header");
            if(isBrowser){
                const body = document.querySelector("body");
            setTimeout(()=>{

                const li = document.querySelectorAll(".controller li");
                const cont = document.querySelector(".controller");
                cont.classList.add("pc");
                
                li.forEach((elem, idx) => {
                    if(cnt === idx) {
                        elem.classList.add("active");
                    } else {
                        elem.classList.remove("active");
                    }
                    
                    if(cnt < 0 ){
                        if(idx === 0){
                            
                            elem.classList.add("active");
                        }
                        //li[0].classList.add("active");
                    } else if(cnt > endPage){
                        if(idx === endPage){
                            elem.classList.add("active");
                        }
                    }
                    
                    elem.addEventListener("click", ()=>{
                        li.forEach((e, i) => {
                            e.classList.remove("active");
                        });
                        setCnt(idx);
                        setIsScrolling(false);
                        elem.classList.add("active");
                    })
                });
            }, 1);

            
            animation(header);
            } else if(isMobile || isTablet){

                const cont = document.querySelector(".controller");
                const body = document.querySelector("body");
                const li = cont.querySelectorAll("li");
                cont.classList.add("mobile");
                body.classList.add("mobile");
                const scrollHeight = (height)=>{
                    window.scroll({
                        top: height,
                        behavior: "smooth",
                    });
                }

                const heightTablet = ()=> {
                    page.forEach((elem, idx)=>{
                        elem.classList.add("tablet");
                    });
                }

                const heightEvent = ()=> {
                    let heightArr = [];
                    page.forEach((elem, idx)=>{
                        heightArr.push(elem.offsetTop);
                    });
                    return heightArr;
                }
                const scrollActive = (idx)=>{

                    li.forEach((elem,idx)=>{
                        elem.classList.remove("active");
                    })
                    li[idx].classList.add("active");
                }
                const scrollEvent = ()=>{
                    const arr = heightEvent();
                    const y = window.scrollY + 100;
                    for(let i=0; i < arr.length; i++){
                        if(y > arr[i] && y < ((i >= arr.length-1) ? arr[i] + 100 : arr[i+1])){
                            scrollActive(i);
                        }
                    }
                }


                li.forEach((elem, idx)=>{
                    
                    elem.addEventListener("click", ()=>{
                        const height = heightEvent()[idx] - 50;
                        scrollHeight(height);
                    });
                });
                heightTablet();
                window.addEventListener("scroll", scrollEvent);
                window.addEventListener("resize", heightEvent);
                heightEvent();
                scrollEvent();
                return ()=>{
                    window.removeEventListener("scroll", scrollEvent);
                    window.removeEventListener("resize", heightEvent);
                    
                }

            }
            

        }, [cnt, page]);

    
    return <>
        <MainStyle />
        <div className='main-container' ref={pageRef} >
            <Popup />
            <Controller>
                {page.map((elem, idx) => (
                    <li key={idx}><span>{elem.getAttribute("data-title")}</span></li>
                ))}
            </Controller>
            <div id="keyvisual" className="container" data-title="ITP소개">
                <div className="container_inner">
                    <Swiper 
                    pagination={{ el:'.keyvisual-pagination_inner' ,clickable: true }}
                    navigation={false}
                    modules={[Pagination,Navigation,Autoplay]}
                    autoplay={{ delay: 6000, disableOnInteraction: false }}
                    ref={swiperRef}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    onSwiper={swiper => swiperRef.current = swiper}>
                        <SwiperSlide>
                            <div className='keyvisual_background_imgae' style={keyvisual[0].style}></div>
                            <div className='keyvisual_detail key01'>
                                <div className='keyvisual_text center'>
                                    <h2 className='animation'>프로그래밍 교육은 전국 최강!!</h2>
                                    <p className='animation'><span style={{fontWeight:900}}>경기<span className='red'>IT</span>프로그래밍 직업교육학원</span>에서는 어디에서도 찾을 수없는</p>
                                    <p className='animation'>특별한 교육을 제공합니다</p>
                                    <p className='animation'>비전공자 프로그래머 교육? 가능합니다!</p>
                                    <p className='animation'>선취업후 수강</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='keyvisual_background_imgae' style={keyvisual[1].style}></div>
                            <div className='keyvisual_detail key02' >
                                <div className='keyvisual_text center' >
                                    <div className='swipper_slide2_lottie' style={{position:"absolute", left:0, zIndex:"-1",opacity:0.5}} ref={swiperLotiie}></div>
                                    <h2 className='animation'>국내 유일 윈도우 기반</h2>
                                    <div className='special_cover'>
                                        <ul className='special card'>
                                            <li className='animation_list'>
                                                <dl>
                                                    <dt>스마트 팩토리</dt>
                                                </dl>
                                            </li>
                                            <li className='animation_list'>
                                                <dl>
                                                    <dt>공장 자동화</dt>
                                                </dl>
                                            </li>
                                            <li className='animation_list'>
                                                <dl>
                                                    <dt>로봇제어</dt>
                                                </dl>
                                            </li>
                                        </ul>
                                    <div className='partition animation' >
                                        <span>전문 교육 기관 - 선 취업 보장</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className='keyvisual_background_imgae' style={keyvisual[1].style}></div>
                            <div className='keyvisual_detail key03'>
                                <div className='keyvisual_text center'>
                                    <p>인공지능</p>
                                    <p>IoT, C언어, 파이썬</p>
                                    <p>전문 교육 기관</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                        <div className='keyvisual-pagination'>
                            <div className='keyvisual-pagination_cover'>
                                <div className='keyvisual-pagination_inner'></div>
                            </div>
                        </div>
                    <div className="mouse_btn" onClick={()=>{
                        setCnt(1);
                        setIsScrolling(false);
                    }}>
                        <ul className='mouse_cover'>
                            <li></li>
                            <li></li>
                            <li></li>
                            {/* <IoIosArrowDown />
                            <IoIosArrowDown />
                            <IoIosArrowDown /> */}
                        </ul>
                    </div>
                </div>
            </div>

            <Page1 title="강의정보" setCnt={setCnt} setIsScrolling={setIsScrolling}/>
            <Lecture title="강사소개" />
            <LoadMap title="오시는길" />
            <Contact title="문의하기" />
        </div>
    </>
}