
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';


export const LoadMap = ({title})=>{

    const [loadMap, setLoadMap] = useState(false);
    const mapStyles = {        
        height: "300px",
        width: "100%"};
      
    const defaultCenter = {
        lat: 37.206476, lng: 127.036683
    }

    
    
    return <div id='loadMap' className="container" data-title={title}>
        <div className="container_inner">
            <div className='title' style={{textAlign:"center"}}>
                <h3 style={{display: "inline-block"}}>{title}</h3>
                <br></br>
                <span>오시는길</span>
            </div>
            <div className="contents">
                <div className='load_map_cover'>
                    
                <LoadScript
                    googleMapsApiKey='AIzaSyAn-JB6HRN2mVdcE7smQDRVMugtaHSq024'
                    onLoad={() => setLoadMap(true)}
                >
                    {loadMap && (
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={17}
                            center={defaultCenter}
                            gestureHandling="greedy"
                        >
                            <Marker position={defaultCenter}/>
                        </GoogleMap>
                    )}
                </LoadScript>
                    <div className='load_txt'>
                        <h4>경기도 화성시 경기대로 1014 , 303호 (병점동, 병점프라자)</h4>
                        <span className=''>병점역 1번출구 도보 10분</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
      
}