



const productList = [
    {
        title : "반도체공정/자동화 장비 & 스마트팩토리 PC 제어 프로그래머 양성 과정",
        idx : 1,
        type: "emp",
        amount : 0,
        event : 0,
        comment: "미정(개강 2개월 전 공개)",
        img: "/images/icons/CC.jpg",
        content : `스마트 팩토리 및 반도체 전/후 공정 장비 제작 시, 전반적인 소프트웨어를 담당할 수 있는 프로그래머를 양성하기 위한 과정입니다.
        과정을 이수한 수강생은 국내외의 PC기반 제어 프로그램을 사용하는 반도체 장비 회사의 장비 제작 SW 프로그래밍이 가능합니다.
        또한 반도체 공정 장비 외에도 산업 전반의 자동화 장비 제작 프로그래머로 진출 할 수 있습니다.
        물론 스마트 팩토리 분야의 프로그래머로의 진출도 가능합니다. 
        대학 강의에서 놓치는, 현장에서 필요한 “실무 프로그래밍”을 수강합니다.
        `,
        teacher : "김성모",
        open : null,
        close : null,
        dayArr: []
    },
    {
        title : "인공지능(딥러닝)과 IoT를 활용한 AI 실무 과정",
        idx : 2,
        comment: "미정(개강 2개월 전 공개)",
        type: "emp",
        amount : 0,
        event : 0,
        img: "/images/icons/tensorFlow.jpg",
        content : `인공지능(딥러닝)과 IoT를 결합한 AI 실무 과정은 현대 기술을 활용하여 실제 문제를 해결하는 방법을 학습하고자 하는 이들을 위한 완벽한 선택입니다. 이 과정에서는 딥러닝 알고리즘과 IoT 기기를 통합하여 스마트 시스템을 개발하는 데 필요한 기술과 노하우를 배울 수 있습니다. 실제 프로젝트 경험을 통해 실무 능력을 키우며, 센서 데이터를 분석하고 인공지능을 활용하여 스마트한 응용 솔루션을 개발하는 과정을 체험할 수 있습니다. 이 과정은 미래 지향적인 기술을 익히며, AI 실무 분야에서 전문가로 성장하고자 하는 이들에게 최적의 교육 환경을 제공합니다.
        `,
        teacher : "김성모",
        open : "2024-01-01",
        close : "2024-03-01",
        dayArr: []
    },
    {
        title : "파이썬 프로그래밍 교육강사 양성과정",
        idx : 3,
        comment: "미정(개강 2개월 전 공개)",
        type: "emp",
        amount : 0,
        event : 0,
        img: "/images/icons/python.jpg",
        content : `'파이썬 프로그래밍 강사 양성 과정'은 파이썬 언어의 전문가로 성장하고자 하는 분들을 위한 특별한 교육입니다. 이 과정에서는 파이썬 프로그래밍의 기초부터 심화까지 폭넓게 학습하며, 효과적인 교육 방법론과 강의 기술을 배울 수 있습니다. 실제 수업과 프로젝트 경험을 통해 학생들에게 파이썬을 가르치는 능력을 키우고, 코딩 교육의 전문가로 성장할 수 있습니다. 뿐만 아니라, 교육 현장에서 필요한 리더십과 커뮤니케이션 기술을 강화하여 미래의 우수한 파이썬 교육강사로 거듭날 수 있는 기회를 제공합니다.
        `,
        teacher : "김성모",
        open : "2024-01-01",
        close : "2024-03-01",
        dayArr: []
    },
    {
        title : "C언어",
        idx : 4,
        comment: "미정(개강 2개월 전 공개)",
        type: "common",
        amount : 0,
        event : 0,
        img: "/images/icons/C.jpg",
        content : `C언어는 IoT의 핵심이자 대표적인 프로그래밍 언어입니다. 초보자도 쉽게 익힐 수 있도록 함수, 포인터, 배열, 구조체 등의 기초 개념부터 심화까지 친절하게 안내합니다. 입문자를 위한 프로그래밍 과정과 학습 방법을 상세히 제시하여, C언어를 통해 프로그래밍의 세계로 여러분을 안내합니다. C언어는 뛰어난 확장성과 성능을 갖추어 있어, 프로그래머들에게 넓은 가능성을 제공합니다.
        `,
        teacher : "김성모",
        open : "2024-01-08",
        close : "2024-01-24",
        dayArr : [
            {
                date : "2024-01-08",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-10",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-12",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-14",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-15",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-17",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-19",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-21",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-22",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2024-01-24",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
        ]
    },
    {
        title : "파이썬",
        idx : 5,
        comment: "미정(개강 2개월 전 공개)",
        type: "common",
        amount : 0,
        event : 0,
        img: "/images/icons/python.jpg",
        content : `파이썬은 다양한 분야에서 활용되며, 초보자부터 전문가까지 모두가 쉽게 배울 수 있는 대표적인 프로그래밍 언어입니다. 그 직관적인 문법과 다양한 라이브러리들은 프로그램 개발을 더욱 효과적으로 만들어 줍니다. 파이썬은 데이터 분석, 인공지능, 웹 개발 등 다양한 영역에서 널리 사용되며, 입문자를 위한 친절한 문서와 광범위한 커뮤니티 지원이 제공되어 프로그래밍을 배우기에 이상적입니다. 파이썬은 빠르고 간편한 프로그램 개발을 원하는 누구에게나 강력한 도구로 작용합니다.`,
        teacher : "김성모",
        open : "2024-01-01",
        close : "2024-03-01",
        dayArr: [
            {
                date : "2023-01-09",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-11",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-13",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-16",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-18",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-20",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-23",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-25",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-27",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
            {
                date : "2023-01-30",
                startTime : "18:00:00",
                endTime : "22:00:00"
            },
        ]
    },
    {
        title : "인공지능 입문",
        idx : 6,
        comment: "미정(개강 2개월 전 공개)",
        type: "common",
        amount : 0,
        event : 0,
        img: "/images/icons/tensorFlow.jpg",
        content : `파이썬 인공지능 입문 과정은 프로그래밍을 모르는 초보자부터 시작하여 머신러닝, 딥러닝, 자연어 처리와 같은 인공지능 기술까지 폭넓게 다루는 훌륭한 기회입니다. 직관적인 파이썬 문법과 다양한 라이브러리를 활용하여 데이터 분석과 예측 모델링을 구축하며, 인공지능 알고리즘을 이해하고 활용하는 방법을 익힐 수 있습니다. 이 과정은 현업에서 필요한 실전 프로젝트와 함께 제공되어 실무 능력을 키우는 데 큰 도움을 줍니다. 파이썬 인공지능 입문 과정은 미래 지향적인 기술을 학습하고자 하는 모든 이들에게 권장됩니다.`,
        teacher : "김성모",
        open : "2024-01-01",
        close : "2024-03-01",
        dayArr: []
    },
    {
        title : "인공지능 자연어 처리_ChatGPT",
        idx : 7,
        type: "common",
        comment: "미정(개강 2개월 전 공개)",
        amount : 0,
        event : 0,
        img: "/images/icons/chat.jpg",
        content : `파이썬 인공지능 자연어 처리_ChatGPT 과정은 텍스트 기반의 인공지능 기술을 파이썬 언어로 배우고 싶은 모든 이에게 적합합니다. 이 과정에서는 자연어 처리 기술과 관련된 핵심 개념과 알고리즘을 익히며, ChatGPT와 같은 언어 모델을 이해하고 활용하는 방법을 배울 수 있습니다. 파이썬을 기반으로 한 직관적인 프로그래밍과 함께, 실전 프로젝트를 통해 자연어 이해, 대화 시스템, 감정 분석 등 다양한 응용 분야에서의 능력을 향상시킬 수 있습니다. 이 과정은 자연어 처리 분야에 입문하고자 하는 이들에게 권해지며, 현업에서 활용 가능한 실전 기술을 습득하고자 하는 분들에게도 큰 도움이 될 것입니다.`,
        teacher : "김성모",
        open : "2024-01-01",
        close : "2024-03-01",
        dayArr: []
    }
]

export const getProductList = (type)=>{
    
    return productList.filter(product => product.type === type);
}

export const getProductView = (idx) => {
    return productList.filter(product => product.idx === idx);
}
