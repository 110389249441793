import styled from "styled-components"

const FooterStyle = styled.div`
width: 100%;
#footer{
	width:100%;
	height:235px;
	background-color: #fff;
	padding: 0 25px;
	box-shadow:0px -3px 10px -3px rgba(0, 0, 0, .2);
}
#footer .footer_inner{
	max-width: var(--max-width);
	margin: 0 auto;
	font-size: 1em;

}
#footer .footer_inner .top{
	width:100%;
	padding: 48px 0 25px 0 ;
	position:relative;
}
#footer .footer_inner .top::after{
	content: "";
	position: absolute;
	display:block;
	left:0;
	bottom:0;
	width:100%;
	height:2px;
	background-color:	#F6F6F6;
}
#footer .footer_inner .top span{
	color: #959595;
	font-size: 1em;
}
#footer .footer_inner .top .box{
	position:absolute;
	right:0;
	bottom:-15px;
	width: 61px;
	height:61px;
	background-color:#414040;
	text-align:center;
	z-index:1;
}
#footer .footer_inner .top .box:hover{
	background-color:var(--main-color);
}

#footer .footer_inner .top .box span{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 61px;
	height:61px;
	color:#fff;
	font-weight: 500;
	color:#fff;
	cursor: pointer;
}
#footer .footer_inner .bottom .left{
	float: left;
	padding-top:25px;
	color: #959595;
}
#footer .footer_inner .bottom .left span{
	font-size:1em;
}
#footer .footer_inner .bottom .right{
	float:right;
	padding-top:25px;
}
#footer .footer_inner .bottom .right li {
	float: left;
	margin-left: 20px;
	color:#696868;
}
#footer .footer_inner .bottom .right li:nth-child(1){
	margin-left:0;
}
#footer .footer_inner .bottom .right li:nth-child(1) a{
	color:var(--main-color);
}

@media only screen and (max-width: 650px){
	
	#footer .footer_inner .bottom .left{
		float:none;
	}
	#footer .footer_inner .bottom .right{
		float: none;
	}
	#footer .footer_inner .top .box, #footer .footer_inner .top .box span{
		width:39px;
		height:39px;
	}
	#footer .footer_inner .top{
		text-align: center;
	}
	#footer .footer_inner .bottom{
		text-align: center;
	}
}
`


export const Footer = ()=>{
    return <FooterStyle>
        <div id="footer">
			<div className="footer_inner">
				<div className="top">
					<span>경기 IT 프로그래밍직업교육학원  │ <br />TEL : 031-232-6855 │ E-MAIL : educoding@naver.com</span>
					{/* <div className="box">
						<span>TOP</span>
					</div> */}
				</div>
				<div className="bottom">
					{/* <div className="right">
						<ul>
							<li><a href="">개인정보처리방침</a></li>
							<li><a href="">이메일무단수집거부</a></li>
						</ul>
					</div> */}
					<div className="left">
						<span>© Copyrights 2023 ITP All Right Reserved.</span>
					</div>
				</div>
			</div>
		</div>
    </FooterStyle>
}