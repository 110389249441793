import { createGlobalStyle } from "styled-components";


export const CommonStyleMain_SA =  createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
	/*font-family: "Roboto", "Noto Sans KR", sans-serifsnas;*/
	font-family: 'Hi Melody', cursive;
	font-family: 'Nanum Pen Script', cursive;
	
}
* {
	box-sizing: border-box;
	font-size: 22px;
}
:root {
    --main-color: #67686b;
	--sub-color: #1d6ceb;
	--font-color-title: #333333;
	--font-color-sub:#6E6E6E;
	--scroll-color-back:#eeeeee73;
	--scroll-color:#7c87988e;
	--button-main-color : #603d99;
	--button-sub-color : rgba(166, 49, 69, 1);
	--main-color-rgba:rgba(166, 49, 69, 1);
	--font-size-title: 1.125em;
	--ui-width: 1400px;
	--aside-width: 200px;
	
}



.notFound_container{
	width: 100%;
	padding: 0 15px;
	
}
.notFound_container .notFound{
	max-width: 300px;
	margin: 0 auto;
}
.notFound_container .notFound_txt{
	display: flex;
	justify-content: center;
	font-size: 22px;
}
.alert_dim{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}
.alert_dim .alert_cover{
	background-color: #fff;
    padding: 20px 15px;
    border-radius: 5px;
    min-width: 280px;
    max-width: 280px;
    text-align: center;
}


.alert_dim .alert_cover p {
	padding: 15px 0;
}
.btn_cover{
	width: 100%;
	text-align: center;
}
.alert_dim .alert_cover button[type= "button"]{
	border: none;
	padding: 10px 20px;
	background-color: var(--button-main-color);
	color: #fff;
	border-radius: 5px;
	font-size: 12px;
	cursor: pointer;
}


.loading_container{
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.2);
	z-index: 999;
}
.loading_container .loading {
	width: 300px;
	
}

html { scrollbar-arrow-color: var(--scroll-color-back);
    scrollbar-Track-Color: var(--scroll-color-back); 
    scrollbar-base-color: var(--scroll-color-back);
    scrollbar-Face-Color: var(--scroll-color-back);
    scrollbar-3dLight-Color: var(--scroll-color-back);         
    scrollbar-DarkShadow-Color: var(--scroll-color-back);
    scrollbar-Highlight-Color: var(--scroll-color-back);
    scrollbar-Shadow-Color: var(--scroll-color-back)}

::-webkit-scrollbar {width:3px; height: 12px;}
::-webkit-scrollbar-button:start:decrement, 
::-webkit-scrollbar-button:end:increment {display: block; width: 0px; height: 0px; background: url() rgba(0,0,0,.05);}
::-webkit-scrollbar-track {  background:var(--scroll-color-back);}
::-webkit-scrollbar-thumb {  background: var(--scroll-color);  border-radius: 6px;}





.dim{display:none;}
.dim.on{display: block;position: fixed;left:0;right: 0;top:0;bottom: 0;z-index:102;background-color: rgba(0,0,0,0.6);text-indent: -999999px;}



#section {
    
	width:100%;
}

#section .top{
	width:100%;
	background-color: rgba(255,255,247);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, .2);
}
#section .top .top_inner{
	margin:0 auto;
	max-width:1200px;
	text-align:center;
	overflow: auto;
	position:relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-use-select: none;
	user-select: none;
	/* Firefox */
	scrollbar-width: none; 

	/* Internet Explorer and Edge */
	-ms-overflow-style: none;

    /* Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}
#section .top .top_inner ul li.active{
	color:#ff4c00;
}
#section .top .top_inner .tab_line{
	height: 4px;
	background-color: #ff4c00;
	transition: width 0.3s, left 0.3s;
	position: absolute;
	left: 0;
	bottom: 0;
	border-radius: 100px;
}
#section .top .top_inner ul{
	display: flex;
	align-items: center;
	width: max-content;
}
#section .top .top_inner ul li{
	position:relative;
	padding: 20px;
	color:#343434;
	cursor: pointer;
	display: inline-block;
}
#section .top .top_inner ul li span{
	position:relative;
}
/* #section .top .top_inner ul li::before{
	content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #e1e1e1;
    width: 1px;
    height: 100%;
} */
#section .top .top_inner ul li::after,
#section .top .top_inner ul li::after{
	content:"";
	display:block;
	position:absolute;
	left:0;
	bottom: 0;
	width:0;
	height:1px;
	background-color:#ff4c00;
	transition: width 0.3s;
}
#section .bottom{
	width:100%;
	font-size:1em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-use-select: none;
	user-select: none;
}
#section .bottom .bottom_inner{
	margin: 0 auto;
	max-width:1200px;
	/*padding: 100px 0;*/
}
#section .bottom .bottom_inner .contentBox{
	position:relative;
	overflow: hidden;
}
#section .bottom .bottom_inner .contentBox > ul{
	display: flex;
	transition:  transform 0.1s;
}
#section .bottom .bottom_inner .contentBox ul li.content{
	
	width:100%;
}

#section .bottom .bottom_inner .contentBox ul li.content ul li{
	position:relative;
	float:left;
	margin-left:40px;
	width: calc((100% - 120px)/4);
	margin-bottom:50px;
}
#section .bottom .bottom_inner .contentBox ul li.content .title{
	width:100%;
	text-align:center;
	padding: 100px 0;
}
#section .bottom .bottom_inner .contentBox ul li.content .title h3 {
	font-size:1.563em;
	color:var(--main-color);
}
#section .bottom .bottom_inner .contentBox ul li.content ul li img{/*ÀÌ¹ÌÁö Å©±â´Â °¡·Î 270 ¼¼·Î 270*/
	width:100%;
	transform:scale(1);
	transition: transform 0.3s;
}
#section .bottom .bottom_inner .contentBox ul li.content ul li:hover img{
	transform:scale(1.1);
}
#section .bottom .bottom_inner .contentBox ul li.content ul li a{
	display:block;
	height:100%;
	overflow:hidden;
}
#section .bottom .bottom_inner .contentBox ul li.content ul li:nth-child(4n+1){
	clear:both;
	margin-left:0;
}
#section .bottom .bottom_inner .contentBox ul li.best ul li::after{
	content:"BEST";
	display:block;
	position:absolute;
	left:0;
	top:0;
	padding:0 5px;
	background-color:#ff4c00;
	color:#fff;
	font-weight:500;
}
#section .bottom .bottom_inner .contentBox ul li.content ul li dl {
	width:100%;
	background-color:#fff;
	text-align:center;
	padding: 20px 0;
	line-height:2;
}
#section .bottom .bottom_inner .contentBox ul li.content ul li dt{
	color:#343434;
	font-weight:300;
}
#section .bottom .bottom_inner .contentBox ul li.content ul li dd{
	font-weight:700;
}
#section .review{
	width:100%;
}
#section .review .reviewInner{
	max-width:1200px;
	margin:0 auto;
    font-size: 1em;
}
#section .review .reviewInner .title{
	width:100%;
	text-align:center;
}

#section .review .reviewInner .box{
	width:100%;
	border-bottom:3px solid #000;
	padding-bottom: 10px;
}
#section .review .reviewInner .news{
	padding: 100px 0;
}
#section .review .reviewInner .left, #section .review .reviewInner .right{
	float:left;
	width:50%;
	padding: 0 20px;
	box-sizing:border-box;
}
#section .review .reviewInner .left .leftTitle{
	float:left;
    font-size: 1.188em;
    color: #333;
}
#section .review .reviewInner .left .more, #section .review .reviewInner .right .more{
	float:right;
	padding-top:8px;
}
#section .review .reviewInner .left .more a, #section .review .reviewInner .right .more a{
	color: #333333;
	font-size:0.750em;
	font-weight:500;
}
#section .review .reviewInner .left .content, #section .review .reviewInner .right .content{
	clear:both;
	line-height:2;
}
#section .review .reviewInner .content ul li a{
	display:block;
}
#section .review .reviewInner .content ul li a span.text{
	float: left;
    width: 70%;
    display: block;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size:1.000em;
}
#section .review .reviewInner .content ul li a span.date{
	float: left;
    width: 30%;
    text-align: right;
    font-size:0.875em;
    color: #cccccc;
}
#section .review .reviewInner .right .right_cover{
    width: 100%;
    height: 173px;
}
#section .review .reviewInner .right .right_cover li {
    float: left;
    width: calc((100% - 20px)/3);
    height: 168px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-left: 10px;
}
#section .review .reviewInner .right .right_cover li:first-child{
    margin-left: 0;
}
#section .review .reviewInner .right .right_cover li img {
    width: 50%;
}
#section .review .reviewInner .right .right_cover li span{
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: block;
    padding: 20px 10px 10px 10px;
    font-size:0.875em;
}


/* 상품상세 */
#product .product{
    padding: 100px 0;
    width: 100%;
    font-size: 1em;
}
#product .product .product_inner{
    padding: 100px 0;
    max-width: 1200px;
    margin: 0 auto;
}
#product .product .product_inner .product_view{
	display: flex;
	justify-content: space-between;
	
}
#product .product .product_inner .left{
    width: 30%;
    text-align: center;
}
#product .product .product_inner .left .main{
    width: 100%;
    margin: 0 auto;
}
#product .product .product_inner .left .main a {
    display: block;
}
#product .product .product_inner .left .main a img{
    width: 100%;
}
#product .product .product_inner .left .sub{
    width: 100%;
    padding-top: 10px;
}
#product .product .product_inner .left .sub .sub_inner{
    display: inline-block;
    margin: 0 auto;
}
#product .product .product_inner .left .sub li{
    float: left;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-left: 10px;
    border: 1px solid #ccc;
}
#product .product .product_inner .left .sub li.active{
    border: 2px solid #000;
}
#product .product .product_inner .left .sub li:first-child{
    margin-left:0;
}
#product .product .product_inner .left .sub li img{
    width: 100%;
}
#product .product .product_inner .right{
    width: 70%;
    border: 1px solid #ededed;
}
#product .product .product_inner .right .title{
    padding: 10px;
}
#product .product .product_inner .right .title h3{
    color: #222222;
    font-size: 1.563em;
    font-weight: 500;
}
#product .product .product_inner .right .price_title{
    width: 100%;
    text-align: right;
    padding-right: 10px;
}
#product .product .product_inner .right .price_title span{
    font-size: 1.250em;
    color:#ff4c00;
}

#product .product .product_inner .right .country,
#product .product .product_inner .right .parcel{
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 10px;
    padding-bottom: 10px;
}
#product .product .product_inner .right .country{
    padding-top: 50px;
    position: relative;
}
#product .product .product_inner .right .country::before{
    content:"";
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    height: 1px;
    background-color:rgba(0,0,0,0.05);
}
#product .product .product_inner .right span.span1{
    font-size: 0.813em;
    color: #333333;
    position: relative;
}
#product .product .product_inner .right span.span1::after{
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #e9e9e9;
    
}
#product .product .product_inner .right span.span2{
    margin-left: 20px;
    font-size: 0.813em;
    color: #333333;
}
#product .product .product_inner .right .quantity{
    padding: 30px 10px;
    text-align: left;
    border-bottom: 2px solid #ededed;
}
#product .product .product_inner .right .quantity .inner{
    display: inline-block;
    border: solid 1px #dde0e3;
}
#product .product .product_inner .right .quantity .btn_down,
#product .product .product_inner .right .quantity .btn_up{
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    width: 34px;
    height: 34px;
    background-color: #f1f2f4;
    text-indent: -999999999px;
}
#product .product .product_inner .right .quantity .btn_down{
    float: left;
}
#product .product .product_inner .right .quantity .btn_up{
    float: right;
}
#product .product .product_inner .right .quantity .btn_down::before,
#product .product .product_inner .right .quantity .btn_up::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 11px;
    height: 1px;
    margin: -1px 0 0 -6px;
    background-color: #555555;
}
#product .product .product_inner .right .quantity .btn_up::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 11px;
    margin: -6px 0 0 -1px;
    background-color: #555555;
}
#product .product .product_inner .right .number{
    width: 44px;
    height: 34px;
    border: solid #eff1f5;
    border-width: 0 1px;
    vertical-align: top;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    line-height: 34px;
    text-align: center;
}
#product .product .product_inner .right .price{
    text-align:right;
    padding: 20px 10px;
}
#product .product .product_inner .right .price span.all {
    font-size: 0.813em;
    color: #333333;
    position: relative;
    margin-right: 20px;
}
#product .product .product_inner .right .price span.all::after {
    content: "";
    position: absolute;
    right: -10px;
    top:0;
    width: 2px;
    height: 100%;
    background-color: #ededed;
}
#product .product .product_inner .right input[type=button] {
    padding: 20px;
    font-size: 0.875em;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    background-color: var(--button-main-color);
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}
#product .product .product_inner .right .orders {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px 0;
}
#product .product .product_inner .sangse{
    border: 1px solid #eaeaea;
    border-top: 0;
    padding: 25px 10px;
    clear: both;
    width: 100%;
    overflow: hidden;
    text-align: center;
}
#product .product .product_inner .sangse img{
    width: 100%;
}
#product .product .product_inner .bottom{
    padding: 50px 0; 
    text-align: right;
}
#product .product .product_inner .bottom li{
    display: inline-block;
    padding: 10px;
    position: relative;
}
#product .product .product_inner .bottom li a{
    display: block;
    color: #333;
}
#product .product .product_inner .bottom li::before{
    content:"";
    display: block;
    position:absolute;
    left:0;
    width: 1px;
    height: 20px;
    background-color:#ccc;
}
#product .product .product_inner .bottom li:first-child::before{
    display: none;
}
#orderBtn{
    padding: 20px;
    font-size: 0.875em;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    background-color: var(--button-main-color);
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}
#product .informationTitle{
    clear: both;
    padding: 10px 0;
    padding-top: 100px;
}
#product table.information{
    width: 100%;
    text-align:left;
    border-spacing: 0;
    border-collapse:collapse;
    border: 0;
    font-size: 1em;
}
#product table.information th{
    background-color: #fafafa;
}
#product table.information td, table.information th{
    border: 1px solid #ededed;
    padding: 10px;
    color: #333333;
    font-size: 0.750em;
}
#product .sangse .namuLogo{
    width: 100%;
}
#product .sangse img {
    width: 100%;
}
#product .sangse p {
    line-height: 1.8;
}
#product .sangse .namuName{
    padding: 20px 0;
}
#product .sangse .sangseText, .lastText{
    padding: 100px 0;
    text-align: center;
}

.comment{
    padding: 50px 0;
    font-family: "Roboto", "Noto Sans KR", sans-serifsnas;
}
.review_score{
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}
.review_score > span{
    margin-left: 15px;
    font-size: 22px;
}
.score_star ul{
    display: flex;
    align-items: center;
}
.score_star ul li{
    color: lightgray;
    font-size: 30px;
    display: flex;
}
.score_star.sub ul li{
    font-size: 12px;
}
.score_star ul li.on{
    color: orange;
}

.score_star ul li.half{
    position: relative;
}
.score_star ul li.half span.star_harf{
    position: absolute;
    left: 0;
    top: 0;
    color: orange;
    display: block;

}

.comment_list ul ul.usb_com{
    padding: 10px 50px;
    
}
.comment_list ul ul.usb_com dl {
    background-color: #eaeaea;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.comment_list ul ul.usb_com li:first-child dl{
    margin-top: 0;
}
.comment_list ul ul.usb_com li {
    position: relative;
}

.comment * {
    font-size: 16px;
}
.comment_category{
    
    display: flex;
    border-top: 2px solid #555;
}
.comment_category li{
    width: 100%;
    text-align: center;
    padding: 20px 10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #fafafa;
    cursor: pointer;
}
.comment_category li.active{
    border-bottom: none;
    background-color: #fff;
}
.comment_category li:first-child{
    margin-left: 0;
}
.comment_list{
    padding: 50px 0;
}
.comment_list > ul > li{
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;
}
.comment_list dl{
    display: flex;
}
.comment_list dl dt{
    margin-right: 10px;
}
.comment_list dl dd{
    width: 100%;
}
.comment_list .writer_info {
    display: flex;
    width: 100%;
}
.comment_list .writer_info span{
    display: block;
    font-size: 16px;
}
.comment_list .writer_info span.writer{
    display: flex;
}
.comment_list .writer_info .regdate{
    margin-left: auto;
    
}
.comment .util{
    display: flex;
    justify-content: start;
    
}
.comment .util .util_service{
    margin-left: auto;
}
.comment .util span {
    font-size: 14px;
    margin-left: 10px;
}
.comment_list .msg{
    padding: 20px 5px;
}
.user_profile{
    width: 50px;
    height: 50px;
    border: 1px solid #eee;
    border-radius: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    overflow: hidden;
}
.user_profile img{
    width: 100%;
}

/* 메인 상품 */
.main_product{
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;
}
h3{
    text-align: center;
    font-size: 34px;
    color: var(--main-color);
}
.main_product ul{
    padding: 100px 15px 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}
.main_product .more{
    width: 100%;
    text-align: right;
    color: #333;
}
.main_product .more a{
    font-size: 30px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
}
.main_product .more a span{
    font-size: 20px;
}
.main_product li{
    width: calc((100% - 156px)/4);
}
.main_product li a{
    display: block;
}
.main_product li img{
    width: 100%;
}

/* 로그인 */
#login{
    padding: 100px 0;
    width: 100%;
    background-color: #eaeaea;
}
#login .title{
    width: 100%;
    text-align: center;
}
#login .title h3{
    display: flex;
    font-size: 60px;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
}

#login .login_container{
    width: 100%;
    padding: 100px 15px;
}
#login .login_cover{
    max-width: 500px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 15px;
    margin: 0 auto;
    box-shadow: 1px 0px 10px 1px rgba(0,0,0,0.2);
}
#login label{
    position: relative;
    display: inline-flex;
    width: 100%;
}
#login label input{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    color: var(--main-color);
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
}
#login label.userId input{
    border-radius: 5px 5px 0 0;
}
#login label.userPw input{
    border-radius: 0 0 5px 5px;
}

#login label input:focus { 
    outline: none !important; 
    border-color: var(--main-color);
}
#login label input:focus + span{
    color: var(--main-color);
}
#login label input::placeholder{
    color: rgba(0,0,0,0.2);
}
#login label input::-webkit-input-placeholder {
    color: rgba(0,0,0,0.2);
}
#login label input:-ms-input-placeholder {
    color: rgba(0,0,0,0.2);
}
#login .btn{
    padding: 25px 0;
    width: 100%;
}
#login .btn-util{
    padding-top: 0;
    padding-bottom: 10px;
}
#login .btn-util:first-child{
    padding-top: 25px;
    padding-bottom: 10px;
}
#login .btn-util button[type="button"]{
    width: 100%;
    border: 0;
    padding: 10px 25px;
    border-radius: 10px;
    color: #fff;
    font-weight: 900;
    text-shadow: 3px 4px 2px rgba(0,0,0,0.2);
    cursor: pointer;
}
#login .btn-util button[type="button"].naver{
    
    background-color: #03c75a;
}
#login .btn-util button[type="button"].kakao{
    
    background-color: #f9e000;
}


#login input[type="submit"]{
    border: none;
    padding: 15px 20px;
    color: #fff;
    border-radius: 10px;
    width: 100%;
    background-color: var(--main-color);
}
#login label span{
    position: absolute;
    left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,0.2);
}

#login .util{
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    color: var(--main-color);
}
#login .util li{
    cursor: pointer;
}
#login .util a{
    color: var(--main-color);
} 


@media only screen and (max-width: 1200px){
	#product .product .product_inner{
        padding: 100px 15px;
    }
	#section .top .top_inner{
		padding: 0 15px;
		box-sizing:border-box;
	}
	#section .bottom .bottom_inner{
		padding:100px 15px;
	}
    #section .bottom{
        font-size:0.845em;
    }
    
}
@media only screen and (max-width: 740px){

    body:has(.productSection){
        overflow: hidden;
    }
    /* #header .top:has(#menu.active){
        z-index: 100;
    } */
    
    #section .bottom .bottom_inner .contentBox ul li.active{
        overflow: auto;
        
    }
    #section .bottom .bottom_inner .contentBox ul li.content{
        padding: 0 15px;
        padding-bottom: 150px;
        
        height: calc(100vh - 135px);
        /* Firefox */
        scrollbar-width: none; 

        /* Internet Explorer and Edge */
        -ms-overflow-style: none;

        /* Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }

	#section .bottom{
		font-size:0.875em;
	}
    .main_product li{
        width: calc((100% - 40px)/2);
    }
    
    .main_product .more{
        width: 100%;
        text-align: center;
        padding: 25px 0;
        background-color: var(--main-color);
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        box-shadow: 5px 0 10px 1px rgba(0,0,0,0.2);
    }
    .main_product .more a{
        display: flex;
        justify-content: center;
        color: #fff;
    }
    #header .top{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 100;
    }
    #header .top.active{
        z-index: 103;
    }
    #section.productSection .top{
        position: fixed;
        left: 0;
        top: 100px;
        z-index: 101;
    }
	#section .bottom .bottom_inner .contentBox ul li.content ul li{
		width:calc((100% - 40px)/2)
	}
	#section .bottom .bottom_inner .contentBox ul li.content ul li:nth-child(4n+1){
		margin-left:40px;
	}
	#section .bottom .bottom_inner .contentBox ul li.content ul li:nth-child(2n+1){
		margin-left:0;
        clear: both;
	}
    #section .review .reviewInner .left, #section .review .reviewInner .right{
        clear: both;
        width:100%;
        padding: 0 20px;
        box-sizing:border-box;
    }
    #section .review .reviewInner .right{
        padding-top: 50px;
    }
    
    #section .top .top_inner ul li::before{
        top: 10px;
    }
    #section .bottom .bottom_inner{
        padding: 0;
    }
    #product .product .product_inner{
        padding: 100px 15px;
    }
    #product .product .product_inner .product_view{
        flex-wrap: wrap;

    }
    #product .product .product_inner .left,
    #product .product .product_inner .right{
        width: 100%;
    }
    #product .product .product_inner .left .main{
        width: 50%;
    }
    #product .product .product_inner .left .sub{
        padding: 20px 0;
    }
}
@media only screen and (max-width: 672px){
	#popup-right{
		display:none;
	}
	#section .top .top_inner ul li span.span1{
		display:none;
	}
    #section .bottom{
        font-size:0.625em;
    }
}
@media only screen and (max-width: 672px){
    .mem_pop img,
	.mem_pop .text{
		width:60%;
	}
   
}
@media only screen and (max-width: 400px){
    #section .review .reviewInner{
        font-size: 0.875em;
    }
    
}
@media only screen and (max-width: 299px){
	.mem_pop img,
	.mem_pop .text,
	.dim.on{
		display:none;
	}
}
`;