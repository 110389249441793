import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"
import { API } from "../../data/config";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { toUrlSafeBase64 } from "../base64";

const AlpakaPagingStyle = styled.div`
    max-width: 600px;
    margin: 0 auto;
    table, ul {
        width: 100%;
    }
    input[type="text"]{
        width: calc(100% - 78px);
        height: 39.2px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0 10px;
    }
    .search{
        text-align:center;
        display:flex;
        justify-content:center;
        gap: 10px;
    }

    ul.content{
        display:flex;
        justify-content:center;
        padding: 50px 0;
        flex-wrap: wrap;
        gap: 20px;
    }
    ul.content li {
        width: 100%;
        text-align:center;
        border-bottom: 1px solid #ccc;
        position:relative;
    }
    ul.content li::before{
        content: "";
        display: block;
        position: absolute;
        left: 10px;
        top: 12px;
        width: 5px;
        height: 30px;
        background-color: var(--main-color2);
        border-radius: 50px;
    }
    ul.content li a{
        display:block;
        padding: 15px 10px;
    }
    .prev, .next{
        width: 30px;
        height: 30px;
        
        position: relative;
        background-color: var(--main-color);
        border-radius: 50px;
        opacity: 0.4;
    }
    .prev.active, .next.active{
        opacity: 1;
        cursor: pointer;
    }
    .prev::after,
    .prev::before{
        content: "";
        position: absolute;
        display:block;
        width: 1px;
        height:10px;
        background-color: #fff;
        left: 50%;
    }
    .prev::after{
        bottom: 7px;
        transform: rotate(-45deg);
    }
    .prev::before{
        top: 6px;
        transform: rotate(45deg);
    }


    .next::after,
    .next::before{
        content: "";
        position: absolute;
        display:block;
        width: 1px;
        height:10px;
        background-color: #fff;
        left: 50%;
    }
    .next::after{
        bottom: 7px;
        transform: rotate(45deg);
    }
    .next::before{
        top: 6px;
        transform: rotate(-45deg);
    }
    .paging_button{
        display:inline-flex;
        gap: 50px;
        justify-content:center;
        align-items:center;
    }
    .paging_button-continer{
        display:flex;
        justify-content:center;
    }
    ul.button{
        display:flex;
        gap:10px;
        justify-content:center;
        width: auto;
    }

    .paging_button ul li{
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: 1px solid var(--main-color);
        border-radius: 50px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#333;
        font-size: 12px;
    }
    .paging_button ul li.active{
        background-color: var(--main-color);
        color:#fff;
    }
    
`;



/*
*@Param req = 보낼 데이터 객체
*@Param secretKey = 파라미터 비밀키
*@Param api = api Url
*@Param column = 헤더와 가져올 데이터 array
*       {
            title : "제목",
            column : "컬럼명",
            link : 눌렀을때 페이지 이동 링크 없을경우 null 입력
        }
*@Param columnTarget : 위에 column에 link가 활성화되었을때 파라미터로 전달할 데이터 예 ex) idx
*@Param btnLimit = 버튼갯수
*@Param viewPage = 페이지 리스트 갯수
*@Param order = 0:desc, 1:asc
*@Param start_date = 시작 날짜
*@Param start_date = 마지막 날짜
*@Param type = table, li
*@Param onSearch = 검색 콜백함수
*/
export const AlpakaPaging = (
    {
        request,
        secretKey,
        api,
        column,
        columnTarget,
        btnLimit=5,
        viewPage=5,
        order=0,
        start_date="",
        end_date="", 
        type="table",
        onSearch
    })=> {

    
    const [search, setSearch] = useState("");
    const [dataList, setDataList] = useState([]);
    const [total, setTotal] = useState(0);
    
    const [req, setReq] = useState({
        ...request,
        viewPage,
        pageNum : 0
    });

    const paramBase64 = (id) => {
        if(secretKey){
            return toUrlSafeBase64(CryptoJS.AES.encrypt(id.toString(), secretKey).toString());
        } else {
            return id;
        }
    }

    useEffect(()=>{
        const getList = async ()=> {
            const res = await axios.post(API + api, req);
            setDataList(res.data.noticeList);
            setTotal(res.data.total);
        }

        getList();
        
    }, [req])

    useEffect(()=> {
        setReq((prev) => prev = {...prev, viewPage})
    }, [viewPage]);

    useEffect(()=>{
        
        setReq((prev) => prev = {...prev, ...request})
    }, [request])

    return <AlpakaPagingStyle>
        <div className="search">
            <input type="text" value={search} onChange={(e)=> setSearch(e.target.value)} placeholder="검색어를 입력해주세요" />
            <button type="button" onClick={()=>{
                onSearch(search);
            }}>검색</button>
        </div>
        <ul className="content">
            {dataList?.map((item, idx)=>(
                <li key={idx}>
                    
                    {column?.map((col, i) => {
                        const content = <span>{item[col.column]}</span>;

                        return (
                            <React.Fragment key={i}>
                            {col.link ? (
                                <Link to={`${col.link}=${paramBase64(item[columnTarget])}`}>
                                {content}
                                </Link>
                            ) : (
                                content
                            )}
                            </React.Fragment>
                        );
                    })}
                </li>
            ))}
        </ul>
        <Button total={total} pageNum={req.pageNum} btnLimit={btnLimit} onCkicked={(idx)=> setReq((prev) => prev = {...prev, pageNum: idx})} />
    </AlpakaPagingStyle>
}

const Button = ({pageNum, total, btnLimit, onCkicked})=>{

    const [num, setNum] = useState([]);

    /* 버튼 NEXT 총 값 */
    const btnNextTotal = useMemo(()=>{
        return Math.ceil(total / btnLimit);
    }, [total])


    /* 버튼 NEXT 현재 값 */
    const [btnNext, setBtnNext] = useState(1);

    
    useEffect(()=>{
        const btn = btnNext * btnLimit;
        const startNum = (btnNext-1) * btnLimit;
        const btnArr= [];

        for(let i=startNum+1; i<=btn; i++){
            btnArr.push(i);
            if(i >= total){
                break;
            }
        }
        
        setNum(btnArr);

    }, [btnNext, total])

    return <div className="paging_button-continer">
                <div className="paging_button">
                    <div className={`prev ${(btnNext == 1) ? "" : "active"}`} onClick={()=>{
                        if(btnNext <= 1) return;
                        
                        onCkicked(((btnNext - 1) * btnLimit) - btnLimit);
                        setBtnNext((prev)=> prev = prev -1);
                    }}></div>
                    <ul className="button">
                        {num?.map((item, idx)=> (
                            
                            <li className={(item === (pageNum+1)) ? "active" : ""} key={idx} onClick={(e)=>onCkicked(item - 1)}>{item}</li>
                        ))}
                    </ul>
                    <div className={`next ${(btnNext >= btnNextTotal) ? "" : "active"}`} onClick={()=>{
                        if(btnNext >= btnNextTotal) return;
                        onCkicked((btnNext * btnLimit));
                        setBtnNext((prev)=> prev = prev + 1);
                    }}></div>
                </div>
        </div>
}