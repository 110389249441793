import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "../../../component/base64";
import { getCookie } from "../../../component/cookies";
import { API } from "../../../data/config";
import axios from "axios";



export const Nav =({id,className, ulClassName})=>{
    const [menuList, setMenuList] = useState(null);
    //const sec_key = "session";
    const site_id = getCookie("site_id");
    //const site_id = CryptoJS.AES.decrypt(fromUrlSafeBase64(getSite), sec_key).toString(CryptoJS.enc.Utf8);
    //console.log("siteID = " + site_id);
    useEffect(() => {
        const fatch = async ()=>{
            const data = {
                site_id : site_id
            }
            const response = await axios.post(API + "/menu/listArray", data);
            
            setMenuList(response.data);

        }
        fatch();
    }, [])


    return <nav id={id} className={className} >
            <ul className={ulClassName}>
                {menuList?.map((elem, idx)=>(
                    <li key={idx}>
                        <Link to={elem.menu_url} target={elem.menu_url.startsWith("http://") || elem.menu_url.startsWith("https://") ? "_blank" : ""}><span>{elem.menu_name}</span></Link>
                        <ul>
                            {elem.subMenu.map((e, i)=>(
                                <li key={i}><Link to={e.menu_url} target={elem.menu_url.startsWith("http://") || elem.menu_url.startsWith("https://") ? "_blank" : ""} ><span>{e.menu_name}</span></Link></li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </nav>
}