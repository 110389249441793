


import { AiOutlineLogin } from "react-icons/ai";
import {RxPerson } from "react-icons/rx";
//import scrollComponent from "../../../component/util/ScrollControll";
import { Link } from "react-router-dom";
import { useState } from "react";
import { HeaderStyle } from "./style/HeaderStyle";
import { S3 } from "../../../data/config";
import { Nav } from "../A/Nav";



export const menuObj = [
    {
        title : "네이버 쇼핑",
        url : "/"
    },
    {
        title : "상품",
        url : "/product/list",
    },
    {
        title : "1vs1 문의하기",
        url : "/",
    },
    {
        title : "공지사항",
        url : "/",
    },
]

function MenuList() {
    

    const [effectComponent, setEffectComponent] = useState(null);
    const handleLinkClick = (event, url, effect) => {
        if (effect) {
            event.preventDefault();
            effect(setEffectComponent);
        }

        const menu = document.querySelector("#menu");
        const bar = document.querySelector("#header .circle_menu .menu_bar");
        const dim = document.querySelector(".dim");
        const body = document.querySelector("body");
        menu.classList.remove("active");
        bar.classList.remove("active");
        dim.classList.remove("on");
        body.classList.remove("fixed");


    };

    return (
        <>
        <Nav id={'menu'} className={"gnb"} ulClassName={'menu_inner'} />
        {effectComponent}
        </>
    );
}


export default function HeaderS_A(){
    
    //const isOver = scrollComponent();
    const vibrate = () => {
        if ('vibrate' in navigator) {
            navigator.vibrate(200);
        }
    };

    return  <HeaderStyle id="header" className="clearfix">
    <div className="top">
        <div className="top_inner">
            <div className="logo">
                <h1><a href="/"><img src={`${S3}/namuhana/logo/logo.png`} alt="logo" /></a></h1>
            </div>
            <div className='menu_cover'>
                <MenuList />
                <div className="uttils">
                    
                    <ul>
                        {/* <li className="myp"><a href="http://namu--hana.com/information/index.php">마이페이지</a></li>
                        <li className="basket"><a href="http://namu--hana.com/basket/index.php">장바구니</a></li>
                        <li className="logout"><a href="http://namu--hana.com/join/logout.php">로그아웃</a></li> */}
                        <li className="login"><Link to="/login"><AiOutlineLogin className='loginIcon'/><span>로그인</span></Link></li>
                        <li className="member"><Link to="/sign"><RxPerson className='memberIcon'/><span>회원가입</span></Link></li>
                    </ul>
                    <div className="circle_menu"
                   
                    onClick={()=>{
                        const menu = document.querySelector("#menu");
                        const bar = document.querySelector("#header .circle_menu .menu_bar");
                        const dim = document.querySelector(".dim");
                        const body = document.querySelector("body");
                        const top = document.querySelector("#header .top");
                        top.classList.toggle("active");
                        menu.classList.toggle("active");
                        bar.classList.toggle("active");
                        dim.classList.toggle("on");
                        body.classList.toggle("fixed");
                        vibrate();
                    }}
                    
                    >
                        <div className="menu_bar">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</HeaderStyle>
}