import styled from "styled-components";




export const MainStyle = styled.div`
#header .uttils ul li a svg{
	display: none;
}
#keyvisual .swiper-container {
	width: 100%;
	height: 300px;/*745px*/
}
#keyvisual .swiper-slide{
	height:100%;
	overflow: hidden;
}
.slider{
	overflow: hidden;
	padding-top: 100px;
}
#keyvisual .swiper-wrapper {
	text-align: center;
	height:100%;
	width:auto;

	
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

#keyvisual .swiper-container .swiper-slide .text{
	width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
}
#keyvisual .swiper-container .swiper-slide .text .text_inner{
	max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
}
#keyvisual .swiper-container .swiper-slide .text .text_inner dl dt{
	font-size: 2.813em;
	font-weight: 400;
	display: block;
	transform: translateY(100px);
	opacity:0;
}
#keyvisual .swiper-container .swiper-slide .text .text_inner dl dd .text2{
	font-size: 3.750em;
	font-weight: 400;
	display: block;
	transform: translateY(100px);
	opacity:0;
}
#keyvisual .swiper-container .swiper-slide .text .text_inner dl dd .text3{
	font-size: 1.250em;
	font-weight: 300;
	opacity:0;
	display: block;
	transform: translateY(100px);
}
#keyvisual .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .img{
	transform: scale(1.1);
	transition: transform 3s;
}
#keyvisual .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .text dl dt{
	transition: transform 1.5s, opacity 1s;
	transform: translateY(0);
	opacity:1;
}
#keyvisual .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .text dl dd .text3{
	transform: translateY(0);
	transition: transform 1.5s, opacity 1s;
	transition-delay: 0.5s;
	opacity:1;
}

#section {
    padding: 100px 0;
	width:100%;
}

`