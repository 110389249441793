
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { API, FOLDER, S3 } from "../../../data/config";
import { Link } from "react-router-dom";
import { MainStyle } from './styled/MainStyle';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { getCookie } from '../../../component/cookies';
import { toUrlSafeBase64 } from '../../../component/base64';





/* keyvisual 슬라이드 */
export const keyvisualObj = [
    {
        img : `${S3}/namuhana/main_keyvisual/slider1-1.png`,
        txt : <dl>
                <dt><span className="text1">나무하나</span><br /></dt>
                <dd><span className="text3">정직한 마음을 담았습니다.</span></dd>
              </dl>
    }
];


function SlideCom(swiperOptions){
    

    return <Swiper {...swiperOptions} className='swiper-container' modules={[Autoplay]} >
        {keyvisualObj.map((obj, index) => {
            const styles = {
                background: `url(${process.env.PUBLIC_URL}${obj.img}) no-repeat center center`,
                backgroundSize: "cover",
            }
            return <SwiperSlide key={index} style={styles}>{
                <>
                    <div className="text">
                        <div className="text_inner">
                            {obj.txt}
                        </div>
                    </div>
                </>
            }</SwiperSlide>;
        })}
    </Swiper>
}



function MainItems(){

    const [productList, setProductList] = useState([]);
    const secretKey = "product_idx";
    const site_id = getCookie("site_id");
    useEffect(()=> {

        
        const getMenu = async ()=> {
            const data = {
                site_id : site_id
            }
            const res = await axios.post(API + "/product/getProductAllList", data);
            setProductList(res.data);
            
        }
        getMenu();
        
        
    }, []);

    return <ul>
                {productList?.map((item, idx) =>(
                    <li key={idx}>
                        <Link to={`/product/view?p=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.product_idx.toString(), secretKey).toString())}`}>
                        <img src={`${S3}/${getCookie("folder")}/product/${item.imgList.find(img => img.img_main === 1)?.img_url}`} alt={item.title} />
                        </Link>
                        <dl>
                            <dt>{item.title}</dt>
                            <dd>{Number(item.amount).toLocaleString()}원</dd>
                        </dl>
                    </li>
                ))}
            </ul>
}

export default function HomeSA(){
    const swiperOptions = {
        slidesPerView: 1,
        autoplay: false,
        loop: true,
        navigation: false,
        pagination: false
    };

    return <MainStyle>
        <div className="slider" id='keyvisual'>
            <SlideCom {...swiperOptions} />
        </div>
        <section id="section">
            <div className='main_product'>
                <div className='title'>
                    <h3>메인상품</h3>
                </div>
                <MainItems />
                <div className='more'>
                    <Link to="/product/list">더보기 <span>&gt;</span></Link>
                </div>
            </div>
            
            <div className="review clearfix">
                <div className="reviewInner">
                    <div className="title">
                        <h3>나무센터</h3>
                    </div>
                    <div className="news clearfix">
                    <div className="left">
                        <div className="box clearfix">
                            <div className="leftTitle">
                                <h3>공지사항</h3>
                            </div>
                            <div className="more"><a href="notice/index.php">더보기</a></div>
                        </div>
                    <div className="content">
                        <ul>
                            <li><a href=""><span className="text">sadasd</span><span className="date">asdsd</span></a></li>
                        </ul>    
                    </div>
                    </div>
                    <div className="right">
                        <div className="right_cover">
                            <ul>
                                <li>
                                    <img src="images/quck1.jpg" />
                                    <span>애호박 1/3, 양파 (중) 1/2 양념된장 2스푼, 물 100ml</span>
                                </li>
                                <li>
                                    <img src="images/quck2.png" />
                                    <span>뚝배기에 양념된장 2스푼과 물 100ml를 넣으세요</span>
                                </li>
                                <li>
                                    <img src="images/quck3.jpg" />
                                    <span>끓으면 호박, 양파 썬 것을 넣고 살짝만 저으세요.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </MainStyle>
}